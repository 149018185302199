<template>
    <div class="sa-page-paziente">
        <div class="sa-page-paziente-content">
            <b-row class="sa-patient-toolbar">
                <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <b-dropdown id="dropdown-1" text="" variant="primary " class="m-md-2 sa-patient-toolbar-btn">
                        <template #button-content>
                            <i class="fas fa-heartbeat "></i>
                        </template>
                        <b-dropdown-item :href="getItemMenuHref('/hyperhealtheventi/visitehh')"><i class="fas fa-clipboard-list sa-icon-menu-color"></i> {{ this.$i18n.t("patient.menu.lblVisit") }}</b-dropdown-item>
                        <!--<b-dropdown-item :href="getItemMenuHref('/medicinadellosportecocardiorefertazione')"><i class="fas fa-clipboard-list sa-icon-menu-color"></i> Ecocardio Refertazione</b-dropdown-item>-->
                    </b-dropdown>
                    <!--<b-dropdown id="dropdown-2" text="" variant="primary " class="m-md-2 sa-patient-toolbar-btn">
            <template #button-content> <i class="fas fa-tint"></i> </template>
            <b-dropdown-item :href="getItemMenuHref('/diabetologiaprimavisita')"><i class="fas fa-clipboard-list sa-icon-menu-color"></i> Diabetologia - Prima Visita</b-dropdown-item>
          </b-dropdown>-->
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8">
                    <b-card class="mb-8">
                        <div class="bg-picture card-box">
                            <div class="profile-info-name">
                                <div class="container-fluid">
                                    <b-row>
                                        <b-col>
                                            <b-row>
                                                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <div style="height: 100%; display: flex; align-items: center;">
                                                        <div style="width: 6rem; height: 6rem; float: left;"><img :src="patientAvatar" class="rounded-circle avatar-xl img-thumbnail float-left mr-3" alt="profile-image" /></div>
                                                        <div style="margin-left: 10px;">
                                                            <h4 class="m-0">{{ data.cognome }} {{ data.nome }}</h4>
                                                            <p class="text-muted">
                                                                <i>{{ data.codiceFiscale }}</i>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                        <b-col class="text-right">
                                            <b-button variant="purple btn-generic btn-notext sa-margin-right" size="sm" @click="onEdit"><b-icon icon="pencil"></b-icon></b-button>
                                            <b-button variant="purple btn-generic btn-notext sa-margin-right" size="sm" v-b-toggle.info-1><b-icon icon="info" scale="2"></b-icon></b-button>
                                        </b-col>
                                    </b-row>
                                </div>
                                <hr />

                                <b-collapse id="info-1" role="tabpanel">
                                    <div class="row">
                                        <div class="col">
                                            <strong>CF</strong><br />
                                            {{ data.codiceFiscale }}
                                        </div>
                                        <div class="col">
                                            <strong>Sesso</strong><br />
                                            <i class="fas fa-venus rosa font-18"></i> {{ data.sesso }}
                                        </div>
                                        <div class="col">
                                            <strong><i class="fas fa-tint rosso"></i> Gruppo S.</strong><br />
                                            {{ data.gruppoSanguigno }}
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col">
                                            <strong>Nata a </strong><br />
                                            {{ data.comuneNascita }} ({{ data.provinciaNascita }})
                                        </div>
                                        <div class="col">
                                            <strong>Il </strong><br />
                                            {{ formatDate(data.dataNascita) }}
                                        </div>
                                        <div class="col">
                                            <strong>Residenza </strong><br />
                                            {{ data.indirizzoResidenza }} {{ data.capResidenza }} {{ data.comuneResidenza }} ({{ data.provinciaResidenza }})
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col">
                                            <strong>Domicilio </strong><br />
                                            {{ data.indirizzoDomicilio }} {{ data.capDomicilio }} {{ data.comuneDomicilio }} ({{ data.provinciaDomicilio }})
                                        </div>
                                        <div class="col">
                                            <strong>Telefono </strong><br />
                                            {{ data.telefono }}
                                        </div>
                                        <div class="col">
                                            <strong>E-Mail </strong><br />
                                            {{ data.email }}
                                        </div>
                                    </div>
                                    <hr />
                                </b-collapse>
                                <h5 class="mt-0">
                                    <span class="text-dark"><i class="fas fa-comment-medical "></i> {{ this.$i18n.t("patients.lblSharedSupportPlan") }}</span>
                                </h5>
                                <!-- <b-button class="float-sm-right " variant="primary btn-toolbar" size="sm" @click="onDashboard"
                  ><b-icon icon="tachometer-alt" variant="secondary"></b-icon>{{ this.$i18n.t("patients.lblGraphChart") }}</b-button
                >
                <b-button class="float-sm-right " variant="primary btn-toolbar" size="sm" @click="onAllegati"
                  ><b-icon icon="paperclip" variant="secondary"></b-icon>{{ this.$i18n.t("patient.attachments.lblAttachments") }}</b-button
                > -->
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </b-card>
                    <paziente-attivita></paziente-attivita>

                    <!-- <b-card>
            <div class="card-box">
              <div class="media mb-2">
                <div class="media-body">
                  <h5 class="mt-0">
                    <span class="text-dark"><i class=" fas fa-capsules"></i> Terapia </span>
                  </h5>

                  <ul class="list-unstyled mb-3">
                    <li v-for="item in terapia" :key="item.id">
                      <strong>{{ item.posologia }}</strong> <strong>{{ item.farmaco }}</strong> <span class="text-danger"> {{ item.frequenza }} </span>
                      <span class="text-danger">inizio terapia {{ formatDate(item.dataInizio) }}</span> <span class="text-danger">{{ item.tempo }}</span>
                      <i class="blockquote-footer"> {{ item.nomeMedico }} {{ item.cognomeMedico }}</i>
                    </li>
                  </ul>
                </div>
              </div>
                media
            </div>
          </b-card> -->
                    <paziente-terapie-attive></paziente-terapie-attive>
                </b-col>
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                    <paziente-diario></paziente-diario>
                    <b-card class="scroll">
                        <paziente-dashboard-component :idPaziente="id" orientation="Vertical"></paziente-dashboard-component>
                    </b-card>
                    <!--<paziente-last-measurements :misure="misure" :key="componentKey" :idPaziente="idPaziente"></paziente-last-measurements>-->
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import female from "@/assets/images/female.jpg";
import male from "@/assets/images/male.jpg";
import UtilityMixin from "../../utility/UtilityMixin";
// import PazienteLastMeasurements from "./PazienteLastMeasurements.vue";
import PazienteDiario from "./PazienteDiario.vue";
import PazienteTerapieAttive from "./PazienteTerapieAttive.vue";
import PazienteAttivita from "./PazienteAttivita.vue";
import PazienteDashboardComponent from "./PazienteDashboardComponent.vue";

export default {
    mixins: [UtilityMixin],
    components: { PazienteDiario, PazienteTerapieAttive, PazienteAttivita, PazienteDashboardComponent },
    data() {
        return {
            titolo: "",
            pathResource: "/pazienti",
            pagelink: "/paziente",
            linkMeasurement: "/lastmeasure",
            data: {},
            misure: [],
            misurePrioritarie: [],
            componentKey: 0,
            pazienteTerapie: [
                {
                    modalita: "Terapia Orale",
                    terapia: "Oki",
                    quantita: "1",
                    unitaMisura: "Milligramo",
                    dataInizio: "19-03-2021",
                    tempo: "Ogni Mattina",
                    nomeMedico: "Michele",
                    cognomeMedico: "Annunziata",
                },
                {
                    modalita: "Terapia in Endovena",
                    terapia: "Cortisone",
                    quantita: "1",
                    unitaMisura: "Siringa",
                    dataInizio: "25-04-2021",
                    tempo: "Ogni 12 ore",
                    nomeMedico: "Michele",
                    cognomeMedico: "Annunziata",
                },
            ],
            diarioStatico: [
                {
                    titolo: "ECG",
                    descrizione: "ELETTROCADIOGRAMMA",
                    data: new Date("2021/03/30"),
                },
                { titolo: "RM", descrizione: "Risonanza Magnetica" },
                { titolo: "ECO", descrizione: "ECOGRAFIA" },
            ],
            misureRange: [
                [
                    {
                        primaryKey: null,
                        createUser: "WITHINGS",
                        createDate: 1632588389909,
                        updateUser: "Callback-WiThings",
                        updateDate: 1632588389000,
                        proprietario: "26656323",
                        user: {
                            id: "0d3d6418-1105-4085-b622-b5b0bee0aa3d",
                            userId: "26656323",
                            accessToken: "084e44ce1a5566ac4eed925ab2e9b42e0a3eaf36",
                            refreshToken: "fc5b3d788993da7fe2807c0e60bd195e9d0257b0",
                            scope: "user.activity,user.info,user.metrics",
                        },
                        id: "125b5eb8-cff0-4b91-9a46-10796cec8e7f",
                        mac: "00-1A-4A-16-01-AA",
                        idAnagrafica: null,
                        istant: 1628254229000,
                        value: "1.76",
                        idMeasure: "4",
                        idDevice: null,
                        idEvento: "1",
                        range: "-2",
                        idStruttura: "HYPER1",
                        isMistake: false,
                        measurename: "Height (meter)",
                        idFilejson: "fileJson",
                    },
                ],
                [
                    {
                        primaryKey: null,
                        createUser: "WITHINGS",
                        createDate: 1632588389909,
                        updateUser: "Callback-WiThings",
                        updateDate: 1632588389000,
                        proprietario: "26656323",
                        user: {
                            id: "0d3d6418-1105-4085-b622-b5b0bee0aa3d",
                            userId: "26656323",
                            accessToken: "084e44ce1a5566ac4eed925ab2e9b42e0a3eaf36",
                            refreshToken: "fc5b3d788993da7fe2807c0e60bd195e9d0257b0",
                            scope: "user.activity,user.info,user.metrics",
                        },
                        id: "125b5eb8-cff0-4b91-9a46-10796cec8e7f",
                        mac: "00-1A-4A-16-01-AA",
                        idAnagrafica: null,
                        istant: 1628254229000,
                        value: "1.76",
                        idMeasure: "4",
                        idDevice: null,
                        idEvento: "1",
                        range: "-2",
                        idStruttura: "HYPER1",
                        isMistake: false,
                        measurename: "Height (meter)",
                        idFilejson: "fileJson",
                    },
                ],
            ],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.params.id;
        },
        patientAvatar: {
            get: function() {
                let me = this;
                if (me.data.sesso === "M") {
                    return male;
                } else {
                    return female;
                }
            },
        },
    },
    created() {
        let me = this;
        me.id = this.$route.params.id;
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
        me.loadMeasurement();
        me.forceRerender();
    },
    methods: {
        forceRerender() {
            let me = this;
            me.componentKey += 1;
        },
        getItemMenuHref(value) {
            let me = this;
            let href = "#/paziente" + value + "/?idPaziente=" + me.id;

            return href;
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
        formatDateTime(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm");
            } else {
                return "---";
            }
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.data = response.data.data;
                })
                .catch((e) => {
                    console.log("Error: " + e.response.status + " " + link);
                });
        },
        onPazienteTeleconsulto: function() {
            let me = this;
            me.$router.replace("/paziente/teleconsulti/" + me.id).catch((err) => {
                err;
            });
        },
        onEdit: function() {
            let me = this;
            me.$router.replace(me.pagelink + "/edit/" + me.id).catch((err) => {
                err;
            });
        },
        onDashboard() {
            let me = this;
            me.$router.replace("paziente/dashboard?idPaziente=" + me.idPaziente).catch((err) => {
                err;
            });
        },
        onAllegati() {
            let me = this;
            me.$router.replace("/paziente/allegati/" + me.id).catch((err) => {
                err;
            });
        },
        loadMeasurement() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/hyperhealth" + me.linkMeasurement + "?idPaziente=" + me.id;
            axios
                .get(link)
                .then((response) => {
                    me.misurePrioritarie = ["Diastolic Blood Pressure (mmHg)", "Systolic Blood Pressure (mmHg)"];
                    console.log(response.data.data);
                    let daOrdinare = response.data.data;
                    for (let i = 0; i < daOrdinare.length; i++) {
                        for (let j = 0; j < me.misurePrioritarie.length; j++) {
                            if (daOrdinare[i].measurename === me.misurePrioritarie[j]) {
                                let scambio = daOrdinare[j];
                                daOrdinare[j] = daOrdinare[i];
                                daOrdinare[i] = scambio;
                            }
                        }
                    }
                    me.misure = daOrdinare;
                })
                .catch((e) => {
                    console.log("Error: " + e.response.status + " " + link);
                });
        },
    },
};
</script>
