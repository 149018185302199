<template>
  <sa-page-list>
    <template slot="toolbar">
      <b-button class="float-sm-right " variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus"></b-icon>{{ this.$i18n.t("global.lblBtnNew") }}</b-button>
      <b-button class="float-sm-right " variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" />{{ this.$i18n.t("global.lblBtnRefresh") }}</b-button>
    </template>
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col sm="6">
            <b-form-input v-model="filtro.titolo" type="search" id="filterInput" :placeholder="this.$i18n.t('agenda.lblTitletoSearch')"></b-form-input>
          </b-col>
          <b-col sm="6">
            <b-form-input v-model="filtro.descrizione" type="search" id="testo" :placeholder="this.$i18n.t('agenda.lblDescriptiontoSearch')"></b-form-input>
          </b-col>
        </b-row>
        <!--<b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                        <b-col sm="">
                            <b-form-input v-model="filtro.codiceFiscale" type="search" id="codiceFiscale" placeholder="Codice Fiscale"></b-form-input>
                        </b-col>

                        <b-col lg="12">
                            <b-input-group size="sm">
                                <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Testo da Cercare"></b-form-input>
                                <b-form-input v-model="filtro.testo" type="search" placeholder="Testo"></b-form-input>
                            </b-input-group>
                        </b-col> 
                    </b-row>
                     <b-col lg="12">
                        <b-input-group size="sm">
                            <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Testo da Cercare"></b-form-input>
                            <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''">Svuota</b-button>
                            </b-input-group-append>
                            <b-form-input v-model="filtro.testo" type="search" placeholder="Testo"></b-form-input>
                        </b-input-group>
                    </b-col> 
                </b-row>-->

        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table
          sticky-header
          ref="table"
          id="tbl"
          :filter="filtro"
          responsive
          stacked="lg"
          striped
          hover
          :items="data"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          sort-icon-left
          head-variant="light"
          class="sa-b-table"
        >
          <template v-slot:cell(titolo)="{ item }">
            <router-link class="sa-edit-link" :to="'/agende/view/' + item.id">{{ item.titolo }}</router-link>
          </template>
          <template v-slot:cell(actions)="row">
            <b-button size="sm" class="no-text" variant="outline-info" style="margin-right:3px" @click="onEdit(row.item, row.index, $event.target)">
              <b-icon icon="pencil" variant="outline-primary" />
            </b-button>
            <b-button size="sm" class="no-text" variant="outline-danger" style="margin-right: 3px" @click="onDelete(row.item, row.index, $event.target)"><b-icon icon="trash" variant="outline-danger"></b-icon></b-button>
            <b-button size="sm" class="no-text" variant="outline-info" @click="onView(row.item, row.index, $event.target)">
              <b-icon icon="calendar-event" variant="outline-primary" />
            </b-button>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-list>
</template>
<script>
import { SaPageList } from "../../../template/sa/components/layout/contents";
import axios from "axios";
export default {
  components: { SaPageList },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      showmolalloading: false,
      pagelink: "/agende",
      pathResource: "/agende",
      currentPage: 1,
      perPage: 20,
      filtro: { descrizione: "", titolo: "" },
      filterOn: [],
      fields: [
        {
          label: this.$i18n.t("agende.lblDiary"),
          key: "titolo",
          sortable: true,
        },
        {
          label: this.$i18n.t("agende.lblDescription"),
          key: "descrizione",
          sortable: true,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 10rem",
        },
      ],

      items: [],
      data: [],
      traduzione: "",
    };
  },
  created: function() {
    this.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.showmolalloading = true;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data;
          me.data = response.data.data;
          me.showmolalloading = false;
        })
        .catch(function(error) {
          me.showmolalloading = false;
          me.$bvToast.toast(error.response.data.messaggio, {
            title: "Agende",
            variant: "danger",
            solid: true,
          });
        });
    },
    onEdit(item) {
      let me = this;
      me.$router.replace(me.pagelink + "/edit/" + item.id).catch((err) => {
        err;
      });
    },
    onView(item) {
      let me = this;
      me.$router.replace(me.pagelink + "/view/" + item.id).catch((err) => {
        err;
      });
    },
    onAdd() {
      let me = this;
      me.$router.replace(me.pathResource + "/edit/-1").catch((error) => {
        error;
      });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onDelete(row) {
      let me = this;

      this.$bvModal
        .msgBoxConfirm([this.$i18n.t("agenda.lblMsgDelete")] + " " + row.titolo + " ?")
        .then((item) => {
          if (item) {
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            axios.delete(link + "/" + row.id).then(() => {
              me.$bvToast.toast([this.$i18n.t("agenda.lblConfirmDelete")], {
                title: "Agenda",
                variant: "info",
                solid: true,
                noCloseButton: true,
                autoHideDelay: 2000,
              });
              me.loadData();
            });
          }
        })
        .catch((err) => {
          this.$bvModal.msgBoxOk(err.response.data.messaggio, {
            title: [this.$i18n.t("agenda.lblEditTitle")],
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          });
        });
    },
    onSubmit() {
      let me = this;
      me.data = [];
      me.data = me.items.filter((element) => element.titolo.toUpperCase().includes(me.filtro.titolo.toUpperCase()) && element.descrizione.toUpperCase().includes(me.filtro.descrizione.toUpperCase()));
    },
    onReset() {
      let me = this;
      me.data = [];
      me.loadData();
    },
  },
};
</script>
