<template>
    <sa-page-view-edit ref="pgaView" :backVisible="true" :editVisible="true" :titolo="data.nome" :linkback="pagelink" :linkedit="linkedit" :showmolalloading="showmolalloading">
        <template slot="btn-left">
            <b-button pill class="float-sm-right sa-btn-header sa-btn-icon-text" variant="light sa-margin-right" size="sm" v-b-modal.modal-center><b-icon icon="tv" variant="secondary" />Elabora</b-button>
            <!--<b-button class="float-sm-right" v-b-modal.modal-center size="sm" variant="light"><font-awesome-icon size="xs" icon="desktop" tabindex="-1"/></b-button>-->
        </template>
        <template slot="filter">
            <b-row>
                <b-col cols="12" lg="1" md="2" class="sa-text-right">Nome:</b-col>
                <b-col cols="12" lg="3" md="4" class="sa-text-left">
                    <span class="sa-text-data">{{ data.nome }}</span>
                </b-col>
                <b-col cols="12" lg="1" md="2" class="sa-text-right">Stato:</b-col>
                <b-col cols="12" lg="3" md="4" class="sa-text-left">
                    <span class="sa-text-data">{{ data.stato }}</span>
                </b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Tipo Avvio:</b-col>
                <b-col cols="12" lg="2" md="4" class="sa-text-left">
                    <span class="sa-text-data">{{ data.tipoAvvio }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Descrizione:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <span class="sa-text-data">{{ data.descrizione }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Nome Classe:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <span class="sa-text-data">{{ data.classeJava }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Ultimo Avvio:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-left">
                    <span class="sa-text-data">{{ formatDate(data.dataOraUltimoStart) }}</span>
                </b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Ultimo Stop:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-left">
                    <span class="sa-text-data">{{ formatDate(data.dataOraUltimoStop) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Data Avvio:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-left">
                    <span class="sa-text-data">{{ formatDate(data.avvioData) }}</span>
                </b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Prossima Exec.:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-left">
                    <span class="sa-text-data">{{ formatDate(data.dataOraProssimaEsecuzione) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Rip. Mese:</b-col>
                <b-col cols="12" lg="1" md="4" class="sa-text-left">
                    <span class="sa-text-data">{{ data.ripetizioneMese }}</span>
                </b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Rip. Giorno:</b-col>
                <b-col cols="12" lg="1" md="4" class="sa-text-left">
                    <span class="sa-text-data">{{ data.ripetizioneGiorno }}</span>
                </b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Rip. Ore:</b-col>
                <b-col cols="12" lg="1" md="4" class="sa-text-left">
                    <span class="sa-text-data">{{ data.ripetizioneOre }}</span>
                </b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Rip. Minuti:</b-col>
                <b-col cols="12" lg="1" md="4" class="sa-text-left">
                    <span class="sa-text-data">{{ data.ripetizioneMinuti }}</span>
                </b-col>
            </b-row>
        </template>
        <template slot="header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" id="tblTask" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(nome)="{ item }">
                        <router-link class="sa-edit-link" :to="'/gesantask/view/' + item.id">{{ item.nome }}</router-link>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
            <b-modal id="modal-center" no-close-on-backdrop centered :title="data.nome" @hidden="onHideStato" @show="onShowStato" ok-only>
                <b-form-textarea size="sm" placeholder="Log Elaborazione" rows="8" v-model="stato.log"></b-form-textarea>
                <template #modal-footer="{ ok }">
                    <b-button size="sm" variant="outline-secondary" @click="onTaskEsegui()"> <font-awesome-icon size="xs" icon="cogs" tabindex="-1" /> Esegui </b-button>
                    <b-button size="sm" variant="info" @click="ok()">
                        Chiudi
                    </b-button>
                </template>
            </b-modal>
        </template>
    </sa-page-view-edit>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
export default {
    components: { SaPageViewEdit },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            task: { nome: "" },
            showmolalloading: false,
            label: "Dettagllio Gruppo",
            pagelink: "/taskmanager",
            linkedit: null,
            currentPage: 1,
            perPage: 30,
            filter: "",
            pathRest: "/gesantask",
            fields: [
                {
                    label: "Avvio",
                    key: "dataOraStart",
                    sortable: true,
                    thStyle: "width: 9rem",
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                    },
                },
                {
                    label: "Stop",
                    key: "dataOraStop",
                    sortable: true,
                    thStyle: "width: 9rem",
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                    },
                },
                {
                    label: "Stato",
                    key: "statoAttuale",
                    sortable: true,
                    thStyle: "width: 8rem",
                },
                {
                    label: "Log Elaborazione",
                    key: "logElaborazione",
                    sortable: true,
                },
            ],
            items: [],
            data: [],
            stato: { log: "" },
            filtro: {},
        };
    },
    created: function() {},
    mounted: function() {
        let me = this;
        me.id = me.$route.params.id;
        me.linkedit = "/taskmanager/edit/" + me.id;
        this.loadData();
    },
    update: function() {
        console.log("Update");
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        loadData() {
            let me = this;
            me.showmolalloading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathRest + "/";
            axios.get(link + me.id).then((response) => {
                me.data = response.data.data;
                //me.$refs["sa-modal-loading"].close();
                me.loadDataDetails();
            });
        },
        loadDataDetails() {
            let me = this;
            //me.$refs["sa-modal-loading"].show();
            let link = process.env.VUE_APP_PATH_API + "/gesantasklog";
            axios.get(link, { params: { idTask: me.id } }).then((response) => {
                me.items = response.data.data;
                me.showmolalloading = false;
            });
        },
        onAdd() {
            let me = this;
            me.$router.replace(me.pathRest + "/edit/-1").catch((err) => {
                err;
            });
        },
        onSubmit: function() {
            let me = this;
            me.loadData();
            return false;
        },
        onTaskStart: function(item) {
            console.log("Start: " + item);
            console.log(item);
        },
        onTaskStop: function(item) {
            console.log("Stop: " + item);
        },
        onEdit() {
            let me = this;
            me.$router.replace("/taskmanager" + "/edit/" + me.data.id).catch((err) => {
                err;
            });
        },
        onBack() {
            let me = this;
            me.$router.replace("/taskmanager").catch((err) => {
                err;
            });
        },
        onShowStato() {
            let me = this;
            me.interval = setInterval(() => me.getStato(), 2000);
        },
        onHideStato() {
            let me = this;
            clearInterval(me.interval);
            me.loadDataDetails();
        },
        getStato() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRest + "/stato/" + me.id;
            axios.get(link).then((response) => {
                me.stato = response.data.data;
            });
        },
        onTaskEsegui() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRest + "/esegui/" + me.id;
            axios
                .get(link)
                .then(() => {})
                .catch(function(error) {
                    me.$bvToast.toast(error.response.data.messaggio, {
                        title: "Sorveglianza Covid Admin",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
    },
};
</script>
