<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnEditVisible="true" :btnBackVisible="true" :linkedit="linkedit" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h5 class="sa-text-left">Dati</h5>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <div class="sa-form-section">
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <strong>strumento</strong>
              <br />
              {{ jsonData.strumento }}
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <strong>lavaggioDisinfezione</strong>
              <br />
              {{ jsonData.lavaggioDisinfezione }}
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <strong>terapieInAtto</strong>
              <br />
              {{ jsonData.terapieInAtto }}
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <strong>indicazione</strong>
              <br />
              {{ jsonData.indicazione }}
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <strong>Esofago</strong>
              <br />
              {{ jsonData.esofago }}
            </b-col>
            <br />
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <strong>Stomaco</strong>
              <br />
              {{ jsonData.stomaco }}
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <strong>Duodeno Digiuno </strong>
              <br />
              {{ jsonData.duodenoDigiuno }}
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <strong>Conclusioni</strong>
              <br />
              {{ jsonData.conclusioni }}
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <strong>Hp test</strong>
              <br />
              {{ jsonData.hpTest }}
            </b-col>
          </b-row>
        </div>
      </div>
      <hr />
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
export default {
  components: { SaPageLayout, PazienteAnagrafica },
  props: {
    utente: {
      type: Object,
      default: function() {
        return { gruppo: { menuGruppo: [] } };
      },
    },
  },
  data() {
    return {
      linkedit: null,
      linkback: null,
      pathResource: "/gastroegds",
      jsonData: { stomaco: "STOMACO STA BENE" },
      showModalLoading: false,
      hpOptions: [
        { text: "Non Effettuato", value: null },
        {
          text: "Positivo",
          value: true,
        },
        {
          text: "Negativo",
          value: false,
        },
      ],
    };
  },

  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo.menuGruppo) {
          if (this.utente.gruppo.menuGruppo.length > 1) {
            return "/paziente/view/" + this.idPaziente;
          } else {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "/paziente/view/" + this.idPaziente;
              } else {
                return "/paziente/homepaziente";
              }
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    },
  },
  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Gastroenterologia - EGDS";
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/gastroenterologia/egds/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    if (me.id !== "-1") {
      me.linkback = "/paziente/gastroenterologia/egds/" + me.id + "?idPaziente=" + me.idPaziente;
      me.loadData();
    } else {
      me.linkback = "/paziente/gastroenterologia/egds?idPaziente=" + me.idPaziente;
    }
  },
};
</script>
