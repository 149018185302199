<template>
  <sa-page-layout :showModalLoading="showModalLoading" :pathResource="pathResource" :btnNewVisible="btnNewVisible" :linkedit="linkedit" @refresh="onRefresh" :btnRefreshVisible="true">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="toolbar-row">
      <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
    </template>
    <!--<template slot="toolbar">
            <b-button class="float-sm-right " variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus" variant="secondary"></b-icon>Nuovo</b-button>
            <b-button class="float-sm-right " variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" variant="secondary" />Refresh</b-button>
        </template>-->
    <!-- <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="6">
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataDal" type="datetime" placeholder="Data Dal"></date-picker>
                    </b-col>
                    <b-col sm="6">
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataAl" type="datetime" placeholder="Data Al"></date-picker>
                    </b-col>
                </b-row>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="6">
                        <b-form-input v-model="filtro.farmaco" type="search" id="farmaco" placeholder="Farmaco"></b-form-input>
                    </b-col>
                    <b-col sm="6">
                        <b-form-input v-model="filtro.stato" type="search" id="stato" placeholder="Stato"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{this.$i18n.t("global.lblFind")}}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{this.$i18n.t("global.lblResetFilter")}}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template> -->
    <template slot="table-header"> </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-tabs content-class="mt-3" class="sa-tab" style="height: 100%;" fill>
          <b-tab active>
            <template #title>
              {{ getTitle("therapy.lblLastTherapy") }}
            </template>
            <div style="padding-top: 10px;">
              <!--  <paziente-terapia-view-component :id="id"> </paziente-terapia-view-component>-->
              <div class="b-table-sticky-header" style="padding-top: 10px;">
                <b-table
                  sticky-header
                  ref="tableActive"
                  :filter="filter"
                  responsive
                  stacked="xl"
                  striped
                  hover
                  :items="elements"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  sort-icon-left
                  head-variant="light"
                  class="sa-b-table"
                >
                  <template v-slot:cell(farmaco)="{ item }">
                    <router-link class="sa-edit-link" :to="'/paziente/terapia/view/' + item.id + '?idPaziente=' + idPaziente">{{ item.farmaco }}</router-link>
                  </template>
                  <template #cell(actions)="row">
                    <b-button
                      :disabled="row.item.stato !== 'ATTIVA'"
                      v-b-modal.modalSomministrazione
                      size="sm"
                      variant="btn btn-purple waves-effect waves-light width-sm no-text"
                      @click="onOpenModal(row.item, row.index, $event.target)"
                    >
                      <b-icon icon="pencil"></b-icon>
                    </b-button>
                  </template>
                  <template #cell(iterazioniFarmaco)="row"
                    ><b-button
                      :disabled="row.item.iterazioneFarmaco === null"
                      v-b-modal.modalIterazioniFarmaco
                      :style="'background-color: ' + returnColor(row.item.iterazioneFarmacoColore) + ' !important; border-color: ' + returnColor(row.item.iterazioneFarmacoColore) + ' !important;'"
                      @click="
                        onOpenModal(row.item, row.index, $event.target);
                        showIterazione(row.item.iterazioneFarmaco);
                      "
                      ><font-awesome-icon icon="pills" size="x2"></font-awesome-icon></b-button
                  ></template>
                </b-table>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <div>{{ getTitle("therapy.lblHistoricalTherapies") }}</div>
            </template>
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
              <b-col lg="3">
                <p>{{ this.$i18n.t("ambulatori.lblTotalRecords") }}: {{ rows }}</p>
              </b-col>
              <b-col lg="9">
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
              </b-col>
            </b-row>
            <div>
              <div class="b-table-sticky-header" style="padding-top: 10px;">
                <b-table
                  sticky-header
                  ref="table"
                  :filter="filter"
                  responsive
                  stacked="xl"
                  striped
                  hover
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  sort-icon-left
                  head-variant="light"
                  class="sa-b-table"
                >
                  <template v-slot:cell(farmaco)="{ item }">
                    <router-link class="sa-edit-link" :to="'/paziente/terapia/view/' + item.id + '?idPaziente=' + idPaziente">{{ item.farmaco }}</router-link>
                  </template>
                  <template #cell(actions)="row">
                    <b-button
                      :disabled="row.item.stato !== 'ATTIVA'"
                      v-b-modal.modalSomministrazione
                      size="sm"
                      variant="btn btn-purple waves-effect waves-light width-sm no-text"
                      @click="onOpenModal(row.item, row.index, $event.target)"
                    >
                      <b-icon icon="pencil"></b-icon>
                    </b-button>
                  </template>
                  <template #cell(iterazioniFarmaco)="row"
                    ><b-button
                      :disabled="row.item.iterazioneFarmaco === null"
                      v-b-modal.modalIterazioniFarmaco
                      :style="'background-color: ' + returnColor(row.item.iterazioneFarmacoColore) + ' !important; border-color: ' + returnColor(row.item.iterazioneFarmacoColore) + ' !important;'"
                      @click="
                        onOpenModal(row.item, row.index, $event.target);
                        showIterazione(row.item.iterazioneFarmaco);
                      "
                      ><font-awesome-icon icon="pills" size="x2"></font-awesome-icon></b-button
                  ></template>
                </b-table>
              </div>
              <hr />
              <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                  <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </template>
    <template slot="table-footer">
      <b-modal id="modalSomministrazione" title="Somministrazione Farmaco" size="lg" @ok="onOk">
        <b-row>
          <b-col md="4">
            <span class="sa-form-label">{{ this.$i18n.t("therapy.lblStatus") }}</span>
            <b-form-select id="inline-form-custom-select-pref" v-model="data.stato" class="mb-2 mr-sm-2 mb-sm-0" :options="statoSomministrazioneOption" :value="null"></b-form-select>
          </b-col>
          <b-col md="4">
            <span class="sa-form-label">{{ this.$i18n.t("therapy.lblDateOfAdministration") }}</span>
            <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="data.statoDataOra" type="datetime" placeholder="Data Somministrazione"></date-picker>
          </b-col>
          <b-col md="4">
            <span class="sa-form-label">{{ this.$i18n.t("therapy.lblQuantity") }}</span>
            <template>
              <b-form-spinbutton id="sb-step" v-model="data.quantita" min="0" max="1000000000" step="0.50"></b-form-spinbutton>
            </template>
          </b-col>
          <b-col md="12">
            <span class="sa-form-label">{{ this.$i18n.t("therapy.lblNote") }}</span>
            <b-form-textarea v-model="data.note" type="search" placeholder="Note" rows="3" no-resize></b-form-textarea>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal id="modalIterazioniFarmaco" title="Interazioni" size="lg" @ok="onOk"><interazione-farmaci-modal :interazioneFarmaci="iterazione"></interazione-farmaci-modal></b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../../utility/UtilityMixin.js";
import PazienteDashboardComponent from "../../components/PazienteDashboardComponent.vue";
import InterazioneFarmaciModal from "../../components/farmaci/InterazioneFarmaciModal.vue";
//import PazienteTerapiaViewComponent from "./PazienteTerapiaViewComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { PazienteAnagrafica, DatePicker, SaPageLayout, PazienteDashboardComponent, InterazioneFarmaciModal },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    rows() {
      return this.items.length;
    },
    btnNewVisible: {
      get: function() {
        let me = this;
        return UtilityMixin.methods.verificaPermessi(me.utente, "pazientiterapia", 2);
      },
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  data() {
    return {
      id: "-1",
      perPage: 10,
      currentPage: 1,
      componentKey: 0,
      filter: null,
      elements: [],
      iterazione: null,
      showModalLoading: false,
      pathResource: "/pazientiterapia",
      linkedit: "/paziente/terapia",
      linkSomministrazione: "/pazienteterapiesomministrazioni",
      linkSomministrazioneStato: "/pazienteterapiesomministrazionistati",
      filtro: {},
      filterOn: [],
      parentData: this.$parent.utente,
      fields: [
        {
          label: this.$i18n.t("therapy.lblDrug"),
          key: "farmaco",
          sortable: true,
        },
        {
          label: this.$i18n.t("therapy.lblQuantity"),
          key: "quantita",
          thStyle: "width: 5rem",
          sortable: true,
        },
        {
          label: this.$i18n.t("therapy.lblFrequency"),
          key: "frequenza",
          thStyle: "width: 5rem",
          sortable: true,
        },
        {
          label: this.$i18n.t("therapy.lblPosology"),
          key: "posologia",
          thStyle: "width: 5rem",
          sortable: true,
        },
        {
          label: this.$i18n.t("therapy.lblStatus"),
          key: "stato",
          sortable: true,
          thStyle: "width: 5rem",
          //tdClass: "text-right",
        },
        {
          label: this.$i18n.t("therapy.lblNote"),
          key: "note",
          sortable: true,
        },
        {
          label: this.$i18n.t("therapy.lblInteractions"),
          key: "iterazioniFarmaco",
          sortable: false,
        },
        {
          label: this.$i18n.t("therapy.lblStartDate"),
          key: "dataInizio",
          sortable: true,
          thStyle: "width: 7rem",
          formatter: (value) => {
            return moment(value).format("DD-MM-YYYY");
          },
        },
        {
          label: this.$i18n.t("therapy.lblAdministration"),
          key: "actions",
          sortable: false,
          thStyle: "width: 2rem",
        },
        // { class: "sa-table-column-action-single", key: "actions", label: "" },
      ],
      items: [],
      data: { stato: "", statoDataOra: null, quantita: 0, note: "", idPaziente: "", idTerapia: "" },
      statoSomministrazioneOption: [{ value: "", text: "--Seleziona Valore--" }],
    };
  },
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  methods: {
    showIterazione(item) {
      let me = this;

      me.iterazione = JSON.parse(item);
      // console.log(me.iterazione);
    },
    stampa(item) {
      console.log(item);
    },
    getTitle(value) {
      return this.$i18n.t(value);
    },
    returnColor(color) {
      // console.log(color);
      if (color !== null || color !== undefined || color !== "") {
        return color;
      } else {
        return "#dbdbdb";
      }
    },
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.isBusy = true;
      me.filtro.idPaziente = me.idPaziente;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data;
          me.id = me.items[0].id;
          me.loadStatoSomministrazione();
          response.data.data.forEach((element) => {
            if (element.stato === "ATTIVA") {
              me.elements.push(element);
            }
            // console.log(element);
          });
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    /*getDisabled(value){
console.log(value.stato);
return (value.stato !== 'ATTIVA');
        },*/
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    // onAdd() {
    //   let me = this;
    //   me.$router.replace(me.pagelink + "/edit/-1?idPaziente=" + me.idPaziente).catch((err) => {
    //     err;
    //   });
    // },
    loadStatoSomministrazione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkSomministrazioneStato;
      axios
        .get(link)
        .then((response) => {
          response.data.data.forEach((element) => {
            me.statoSomministrazioneOption.push({ value: element.descrizione, text: element.descrizione });
          });
        })
        .catch((e) => {
          console.log("Error: " + e.response.status + " " + link);
        });
    },
    onOpenModal(item) {
      let me = this;
      me.data.idPaziente = me.idPaziente;
      me.data.idTerapia = item.id;
      me.data.statoDataOra = Date.now();
      me.data.quantita = item.quantita;
    },
    onOk() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkSomministrazione + "?idPaziente=" + me.idPaziente;
      let jsonData = JSON.stringify(me.data);
      axios.post(link, jsonData).then(() => {
        me.showModalLoading = false;
        me.loadData();
      });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
  },
};
</script>
<style scoped>
.col-lg-12 {
  height: 35px;
}
</style>
