import Pazienti from "../";
import PazientiSic from "../sic";

const routes = [
    { name: "PazientiList", path: "/pazienti", component: Pazienti.PazientiList, meta: { title: "pazienti.lblTitle" } },
    { name: "PazienteEdit", path: "/pazienti/edit/:id", component: Pazienti.PazienteEdit, meta: { title: "pazienti.lblTitle" } },
    { name: "PazientiListSic", path: "/pazientisic", component: PazientiSic.PazientiList, meta: { title: "pazienti.lblTitle" } },
    { name: "PazienteEditSic", path: "/pazientisic/edit/:id", component: PazientiSic.PazienteEdit, meta: { title: "pazienti.lblTitle" } },
];

export default routes;
