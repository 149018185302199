<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" :btnBackVisible="false" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h5 class="sa-text-left">Dati</h5>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <!-- InizioBody  -->
        <h6 class="sa-text-left">{{ this.$i18n.t("menu.pazienti.gastroenterology.anamnesis") }}</h6>
        <div class="sa-form-section">
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <strong>{{ this.$i18n.t("patients.lblMother") }}</strong>
              <br />
              <b-form-select v-model="jsonData.madre" :options="statoGenitore"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <strong>{{ this.$i18n.t("patients.lblFather") }}</strong>
              <br />
              <b-form-select v-model="jsonData.padre" :options="statoGenitore"></b-form-select>
            </b-col>
          </b-row>
        </div>
        <div class="sa-form-section">
          <b-row
            ><b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <strong>Fratelli</strong>
              <br />
              <b-form-checkbox v-model="jsonData.fratelli" switch :checked="jsonData.fratelli"></b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <strong>Numero</strong>
              <br />
              <b-form-input type="number" v-model="jsonData.numeroFratelli"></b-form-input>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <strong>Sorelle</strong>
              <br />
              <b-form-checkbox v-model="jsonData.sorelle" switch :checked="jsonData.sorelle"></b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <strong>Sorelle</strong>
              <br />
              <b-form-input type="number" v-model="jsonData.numeroSorelle"></b-form-input>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <strong> Coniuge</strong>
              <br />
              <b-form-checkbox v-model="jsonData.coniuge" switch :checked="jsonData.coniuge"></b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <strong> Stato Coniuge</strong>
              <br />
              <b-form-select v-model="jsonData.statoConiuge" :options="statoGenitore"></b-form-select> </b-col
            ><b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <strong>Figli</strong>
              <br />
              <b-form-checkbox v-model="jsonData.figli" switch :checked="jsonData.figli"></b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <strong>Numero</strong>
              <br />
              <b-form-input type="number" v-model="jsonData.numeroFigli"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <strong>Altro</strong>
              <br />
              <b-form-input type="text" v-model="jsonData.altro"></b-form-input>
            </b-col>
          </b-row>
        </div>
        <div class="sa-form-section">
          <b-row
            ><b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <strong>Tra i componenti del nucleo familiare è stata presente una delle seguenti malattie:</strong>
              <br /></b-col
            ><b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <strong>Calcolosi della colecisti</strong>
              <br />
              <b-form-select v-model="jsonData.calcolosi" :options="opzioniMalattie"></b-form-select> </b-col
            ><b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <strong>Ulcera gastrica</strong>
              <br />
              <b-form-select v-model="jsonData.ulceraGastrica" :options="opzioniMalattie"></b-form-select> </b-col
            ><b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <strong>Ulcera duodenale</strong>
              <br />
              <b-form-select v-model="jsonData.ulceraDuodenale" :options="opzioniMalattie"></b-form-select> </b-col
            ><b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <strong>Carcinoma dello stomaco</strong>
              <br />
              <b-form-select v-model="jsonData.carciromaStomaco" :options="opzioniMalattie"></b-form-select> </b-col
            ><b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <strong>Linfoma gastrico</strong>
              <br />
              <b-form-select v-model="jsonData.linfomaGastrico" :options="opzioniMalattie"></b-form-select> </b-col
            ><b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <strong>Esofagite da reflusso</strong>
              <br />
              <b-form-select v-model="jsonData.esofagite" :options="opzioniMalattie"></b-form-select> </b-col
            ><b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <strong>Carcinoma Colon</strong>
              <br />
              <b-form-select v-model="jsonData.carciromaColon" :options="opzioniMalattie"></b-form-select> </b-col
            ><b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <strong>Altro</strong>
              <br />
              <b-form-input type="text" v-model="jsonData.altroMalattie"></b-form-input> </b-col
          ></b-row>
        </div>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: { SaPageLayout, PazienteAnagrafica },
  data() {
    return {
      linkback: null,
      pathResource: "/gastroanamnesifamiliare",
      jsonData: {},
      showModalLoading: false,
      statoGenitore: [
        { text: "NON RILEVATO", value: null },
        { text: "Vivente", value: "vivente" },
        { text: "Deceduto", value: "deceduto" },
      ],
      opzioniMalattie: [
        { text: "NON RILEVATO", value: null },
        { text: "Sì", value: true },
        { text: "No", value: false },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo.menuGruppo) {
          if (this.utente.gruppo.menuGruppo.length > 1) {
            return "/paziente/view/" + this.idPaziente;
          } else {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "/paziente/view/" + this.idPaziente;
              } else {
                return "/paziente/homepaziente";
              }
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Gastroenterologia - Anamnesi Familiare";
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/gastroenterologia/anamnesifamiliare/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    if (me.id !== "-1") {
      me.linkback = "/paziente/gastroenterologia/anamnesifamiliare/view/" + me.id + "?idPaziente=" + me.idPaziente;
      me.loadData();
    } else {
      me.linkback = "/paziente/gastroenterologia/anamnesifamiliare?idPaziente=" + me.idPaziente;
    }
  },

  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
    onFormattText(value) {
      let me = this;
      switch (value) {
        case "coincidenzaConMalattie":
          me.jsonData.coincidenzaConMalattieText = me.jsonData.coincidenzaConMalattie ? me.jsonData.coincidenzaConMalattieText : "";
          break;
        case "coincidenzaConAltro":
          me.jsonData.coincidenzaConAltroText = me.jsonData.coincidenzaConAltro ? me.jsonData.coincidenzaConAltroText : "";
          break;
        case "patologieConcomitantiAltreEndocrinopatie":
          me.jsonData.patologieConcomitantiAltreEndocrinopatieText = me.jsonData.patologieConcomitantiAltreEndocrinopatie ? me.jsonData.patologieConcomitantiAltreEndocrinopatieText : "";
          break;
        case "terapiePraticateAltriFarmaci":
          me.jsonData.terapiePraticateAltriFarmaciText = me.jsonData.terapiePraticateAltriFarmaci ? me.jsonData.terapiePraticateAltriFarmaciText : "";
          break;
        case "terapiePraticateInsulina":
          me.jsonData.terapiePraticateInsulinaText = me.jsonData.terapiePraticateInsulina ? me.jsonData.terapiePraticateInsulinaText : "";
          break;
        case "patologieConcomitantiAltro":
          me.jsonData.patologieConcomitantiAltroText = me.jsonData.patologieConcomitantiAltro ? me.jsonData.patologieConcomitantiAltroText : "";
          break;
        case "terapiePraticateAntidiabeticiOrali":
          me.jsonData.terapiePraticateAntidiabeticiOraliText = me.jsonData.terapiePraticateAntidiabeticiOrali ? me.jsonData.terapiePraticateAntidiabeticiOraliText : "";
          break;
      }
    },
  },
};
</script>
