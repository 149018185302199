<template>
  <b-card class="sc-card-max-height" style="height: 100%" no-body>
    <div class="sa-calendar-day-slot" v-for="(disponibilita, i) in disponibilita[0].disponibilita" :key="i">
      <div class="sa-calendar-day-slot-hour">
        <span class="sa-calendar-day-slot-hour-start">{{ disponibilita.dalleOre }}</span>
        <span class="sa-calendar-day-slot-hour-end">{{ disponibilita.alleOre }}</span>
      </div>
      <div class="sa-calendar-day-slot-text">
        <agenda-slot
          :index="getIndexDisponibilita(disponibilita, i)"
          :idAgenda="idAgenda"
          :codicePrestazione="codicePrestazione"
          :descrizionePrestazione="descrizionePrestazione"
          :costo="costo"
          :slotDisponibilita="disponibilita"
          @update="updateData"
        ></agenda-slot>
      </div>
    </div>
  </b-card>
</template>
<script>
import AgendaSlot from "./AgendaSlot.vue";

export default {
  components: { AgendaSlot },
  props: {
    disponibilita: {
      type: Array,
      default: function() {
        return [];
      },
    },
    codicePrestazione: String,
    descrizionePrestazione: String,
    costo: Number,
    idAgenda: String,
  },
  data() {
    return {
      btnListaVariant: "outline-primary",
      btnGrigliaVariant: "outline-primary",
      riferimento: "",
      periodo: "",
      subTitle: "",
    };
  },
  mounted: function() {},
  methods: {
    getIndex(index) {
      return "day_" + index;
    },
    getIndexDisponibilita(disponibilita, index) {
      return "day_" + disponibilita.dataRiferimento + "_" + index;
    },
    updateData() {
      let me = this;
      me.$emit("update");
    },
  },
};
</script>
<style scoped>
.sa-calendar-day {
  border-top: 1px solid #d0d0d0;
  display: block;
  outline: none;
  height: 100% !important;
}
.sa-calendar-day-slot {
  height: 3rem;
}
.sa-calendar-day-slot-hour {
  width: 5rem;
  float: inline-start;
  height: 100%;
  border-bottom: 1px solid #d0d0d0;
}
.sa-calendar-day-slot-hour-start {
  display: inline-block;
  font-size: 0.8rem;
}
.sa-calendar-day-slot-hour-end {
  display: inline-block;
  font-size: 0.7rem;
  width: 100%;
  text-align: right;
  padding-right: 0.2rem;
}
.sa-calendar-day-slot-text {
  border-left: 1px solid #d0d0d0;
  border-bottom: 1px solid #d0d0d0;
  border-right: 1px solid #d0d0d0;
  margin-left: 5rem;
  padding-left: 0.2rem;
  height: 100%;
  width: 100%;
}

.sa-calendar-day-slot-text div {
  height: 100%;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
}

.tab-griglia {
  height: 100% !important;
}

.tabs-griglia {
  height: 100%;
}

.tab-content {
  height: 100%;
}
</style>
