<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" :btnBackVisible="false" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h5 class="sa-text-left">Visita EGDS</h5>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-row>
          <b-col>
            <b-tabs content-class="mt-3" align="center" justified>
              <br />
              <b-tab title="Dati" active>
                <div class="sa-form-section">
                  <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <strong>strumento</strong>
                      <br />
                      <b-form-select v-model="jsonData.strumento" type="text"></b-form-select>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <strong>lavaggioDisinfezione</strong>
                      <br />
                      <b-form-select v-model="jsonData.lavaggioDisinfezione" type="text"></b-form-select>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <strong>terapieInAtto</strong>
                      <br />
                      <b-form-input v-model="jsonData.terapieInAtto" type="text"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <strong>indicazione</strong>
                      <br />
                      <b-form-select v-model="jsonData.indicazione" type="text"></b-form-select>
                    </b-col>
                  </b-row>
                  <hr />
                  <b-row>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                      <strong>Esofago</strong>
                      <br />
                      <b-form-textarea v-model="jsonData.esofago" type="text" no-resize></b-form-textarea>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                      <strong>Stomaco</strong>
                      <br />
                      <b-form-textarea v-model="jsonData.stomaco" type="text" no-resize></b-form-textarea>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                      <strong>Duodeno Digiuno </strong>
                      <br />
                      <b-form-textarea v-model="jsonData.duodenoDigiuno" type="text" no-resize></b-form-textarea>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                      <strong>Conclusioni</strong>
                      <br />
                      <b-form-textarea v-model="jsonData.conclusioni" type="text" no-resize></b-form-textarea>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                      <strong>Hp test</strong>
                      <br />
                      <b-form-select v-model="jsonData.hpTest" :options="hpOptions" :value="null"></b-form-select>
                    </b-col>
                  </b-row>
                </div>
              </b-tab>
              <b-tab title="Foto"> page foto </b-tab>
              <b-tab title="Allegati"> page allegati </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
        <br />
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
// import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
export default {
  components: { SaPageLayout, PazienteAnagrafica },
  props: {
    utente: {
      type: Object,
      default: function() {
        return { gruppo: { menuGruppo: [] } };
      },
    },
  },
  data() {
    return {
      linkback: null,
      pathResource: "/gastroegds",
      jsonData: {},
      showModalLoading: false,
      hpOptions: [
        { text: "Non Effettuato", value: null },
        {
          text: "Positivo",
          value: true,
        },
        {
          text: "Negativo",
          value: false,
        },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        /*let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
            axios.get(link).then((response) => {
              me.jsonData = response.data.data;
              me.showModalLoading = false;
            });*/
      }
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Gastroenterologia - EGDS";
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/gastroenterologia/egds/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    if (me.id !== "-1") {
      me.linkback = "/paziente/gastroenterologia/egds/view/" + me.id + "?idPaziente=" + me.idPaziente;
      me.loadData();
    } else {
      me.linkback = "/paziente/gastroenterologia/egds?idPaziente=" + me.idPaziente;
    }
  },
};
</script>
