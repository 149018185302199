import GastroAnamnesiPatologicaList from "./components/GastroAnamnesiPatologicaList.vue";
import GastroAnamnesiPatologicaEdit from "./components/GastroAnamnesiPatologicaEdit.vue";
import GastroAnamnesiPatologicaView from "./components/GastroAnamnesiPatologicaView.vue";
import GastroAnamnesiFisiologicaList from "./components/GastroAnamnesiFisiologicaList.vue";
import GastroAnamnesiFisiologicaEdit from "./components/GastroAnamnesiFisiologicaEdit.vue";
import GastroAnamnesiFisiologicaView from "./components/GastroAnamnesiFisiologicaView.vue";
import GastroAnamnesiFamiliareList from "./components/GastroAnamnesiFamiliareList.vue";
import GastroAnamnesiFamiliareEdit from "./components/GastroAnamnesiFamiliareEdit.vue";
import GastroAnamnesiFamiliareView from "./components/GastroAnamnesiFamiliareView.vue";
import GastroAnoRettoColonscopiaList from "./components/GastroAnoRettoColonscopiaList.vue";
import GastroAnoRettoColonscopiaEdit from "./components/GastroAnoRettoColonscopiaEdit.vue";
import GastroAnoRettoColonscopiaView from "./components/GastroAnoRettoColonscopiaView.vue";
import EgdsEdit from "./components/EgdsEdit.vue";
import EgdsView from "./components/EgdsView.vue";
import EgdsList from "./components/EgdsList.vue";
import VisitaGastroenterologicaEdit from "./components/VisitaGastroenterologicaEdit.vue";

export default {
  GastroAnamnesiPatologicaList,
  GastroAnamnesiPatologicaEdit,
  GastroAnamnesiPatologicaView,
  GastroAnamnesiFisiologicaList,
  GastroAnamnesiFisiologicaEdit,
  GastroAnamnesiFisiologicaView,
  GastroAnamnesiFamiliareList,
  GastroAnamnesiFamiliareEdit,
  GastroAnamnesiFamiliareView,
  GastroAnoRettoColonscopiaList,
  GastroAnoRettoColonscopiaEdit,
  GastroAnoRettoColonscopiaView,
  EgdsEdit,
  EgdsView,
  EgdsList,
  VisitaGastroenterologicaEdit,
};
