<template>
    <sa-layout-page>
        <sa-nav-bar :btnChatVisible="verificaPermessi" :image="avatar" :pageTitle="pageTitle" :flagimage="flagimage" :languageMenu="languageMenu" :userName="nominativoUtente" @update="onChatVisibleUpdate" />
        <sa-aside v-bind:externalmenu="menu" v-bind:nominativoUtente="nominativoUtente" v-bind:ruolo="ruolo" />
        <sa-content v-sa-aside-toggle>
            <b-container slot="header" fluid>
                <b-row class="mb-2">
                    <b-col sm="6">
                        <h1 class="m-0 text-dark">{{ $t("login.welcome") }} {{ nominativoUtente }}</h1>
                    </b-col>
                    <b-col sm="6" class="d-flex justify-content-end">
                        <b-button-toolbar class="float-right" aria-label="Toolbar with button groups and dropdown menu">
                            <!--<b-button v-if="editButton" variant="outline-secondary sa-margin-left">Modifica</b-button>
                            <b-button v-if="undoButton" variant="outline-danger sa-margin-left invisible">Indietro</b-button>-->
                        </b-button-toolbar>
                    </b-col>
                </b-row>
            </b-container>
            <transition name="fade" mode="out-in">
                <router-view />
            </transition>
        </sa-content>
        <div id="sidebar-overlay" v-sa-aside-toggle></div>
        <div><chat v-if="verificaPermessi" :utente="user" :hidden="chatHidden" @update="onChatVisibleUpdate"></chat></div>
    </sa-layout-page>
</template>
<script>
import axios from "axios";
import { SaNavBar, SaAside } from "../template/layout";
import Chat from "../chat/componets/Chat.vue";
import UtilityMixin from "../utility/UtilityMixin.js";
//import flagimage from
//import { SaModalLoading } from "../../components/modal";

export default {
    name: "Main",
    mixins: [UtilityMixin],
    components: { SaNavBar, SaAside, Chat },
    computed: {
        title() {
            let title = process.env.VUE_APP_NAME;
            if (this.$route.meta && this.$route.meta.label) {
                title = this.$route.meta.label;
            } else if (this.$route.name) {
                title = this.$route.name;
            }
            return title;
        },
        breadcrumbs() {
            return this.$route.matched;
        },
        pageTitle() {
            let title = this.$i18n.t(this.$route.meta.title); //$i18n.t(this.$route.meta.title);
            return title;
        },
        verificaPermessi: {
            get: function() {
                return UtilityMixin.methods.verificaPermessi(this.user, "chat", 1);
            },
            set: function() {},
        },
    },
    data() {
        return {
            flagimage: "",
            test: 0,
            avatar: "",
            undoButton: false,
            editButton: false,
            chatHidden: true,
            nominativoUtente: "",
            ruolo: "",
            menu: [],
            user: null,
            languageMenu: [
                { flag: require("../../assets/images/it.svg"), language: "it", languageDescription: "Italiano" },
                { flag: require("../../assets/images/en.svg"), language: "en", languageDescription: "English" },
                { flag: require("../../assets/images/hr.png"), language: "hr", languageDescription: "Hrvatski" },
                { flag: require("../../assets/images/sv.svg"), language: "sv", languageDescription: "Svenska" },
                { flag: require("../../assets/images/tr.svg"), language: "tr", languageDescription: "Türkçe" },
            ],
        };
    },
    mounted() {
        let me = this;

        me.flagimage = require("../../assets/images/" + this.$i18n.locale + ".svg");
        window.addEventListener("resize", this.onResize);
    },
    ready: function() {},
    created() {
        this.loadUserData();
        this.updateSidebar();
        let me = this;
        this.interval = setInterval(() => me.verificaSessione(), 180000);
    },
    destroyed() {},
    methods: {
        verificaSessione() {
            let link = process.env.VUE_APP_PATH_API + "/sessioncontrol";
            axios
                .get(link)
                .then(() => {})
                .catch(() => {
                    clearInterval(this.interval);
                    this.$router.push("/login");
                });
        },
        loadUserData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/users/profile";
            axios
                .get(link)
                .then((response) => {
                    let utente = response.data.data;
                    me.user = utente;
                    me.nominativoUtente = utente.lastname + " " + utente.firstname;
                    me.ruolo = utente.profilo;
                    me.menu = response.data.data.gruppo.menuGruppo;
                    if (utente.gruppo.menuGruppo.length === 1) {
                        if (utente.gruppo.menuGruppo[0].routeId === "homepaziente") {
                            me.$router.replace("paziente/homepaziente").catch((err) => {
                                err;
                            });
                        }
                    } else if (utente.gruppo.menuGruppo.length > 0) {
                        let present = false;
                        utente.gruppo.menuGruppo.forEach((element) => {
                            if (this.$router.currentRoute.path.includes(element.viewId)) {
                                present = true;
                            }
                        });
                        if (!present) {
                            me.$router.replace(utente.gruppo.menuGruppo[0].viewId).catch((err) => {
                                err;
                            });
                        }
                    }
                    //me.$refs["sa-modal-loading"].close();
                })
                .catch((e) => {
                    this.errors.push(e);
                });
        },
        onChatVisibleUpdate() {
            let me = this;
            me.chatHidden = !me.chatHidden;
        },
        onResize() {
            this.updateSidebar();
        },
        updateSidebar() {
            if (window.innerWidth > 1024) {
                document.body.classList.add("sidebar-mini");
                document.body.classList.add("layout-fixed");
                document.body.classList.remove("sidebar-closed");
                document.body.classList.remove("sidebar-collapse");
            } else if (window.innerWidth <= 1024) {
                document.body.classList.add("sidebar-mini");
                document.body.classList.add("layout-fixed");
                document.body.classList.add("sidebar-closed");
                document.body.classList.add("sidebar-collapse");
            }
        },
    },
};
</script>
