<template>
    <sa-page-layout>
        <template slot="toolbar-title">
            <!-- <paziente-anagrafica :idPaziente="idPaziente"></paziente-anagrafica> -->
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
            <div style="width: 1030px"></div>
        </template>
        <template slot="toolbar-row">
            <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
        </template>
        <template slot="toolbar">
            <!-- <b-button class="float-sm-right " variant="secondary btn-toolbar" size="sm" @click="onBack"><b-icon icon="chevron-left" variant="secondary" />{{ this.$i18n.t("global.lblBtnBack") }}</b-button> -->
        </template>

        <template slot="table-header">
            <b-row>
                <b-col cols="12" lg="4" md="4" class="sa-text-right">
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="selezioneDataIniziale" type="datetime" placeholder="Seleziona intervallo iniziale"></date-picker>
                </b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-right">
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="selezioneDataFinale" type="datetime" placeholder="Seleziona intervallo finale"></date-picker>
                </b-col>
                <b-button size="sm" variant="primary" @click="onSubmit"><b-icon icon="check"></b-icon>{{ this.$i18n.t("patient.lblBtnConfirm") }}</b-button>
            </b-row>

            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-for="item in arrayChart" :key="item.index">
                    <b-row>
                        <b-col cols="11">
                            <line-chart :chart-data="returnChart(item)" :height="350" :width="450" v-if="loaded" :options="returnOption(item)"></line-chart>
                        </b-col>
                        <b-col cols="1" class="text-center"
                            ><b-button v-b-modal="'modal-xl-' + item" variant="primary" class="no-text"><b-icon icon="search"></b-icon></b-button>
                            <div v-if="loaded">
                                <b-modal :id="'modal-xl-' + item" size="xl">
                                    <b-tabs>
                                        <b-tab :title="returnlabel(item)"> <line-chart :chart-data="returnChart(item)" :height="350" :width="450" v-if="loaded" :options="returnOption(item)"></line-chart></b-tab>
                                        <b-tab :title="returnlabel('ELENCO')"><misure-list :chart="returnChart(item)" :idPaziente="idPaziente"></misure-list></b-tab>
                                    </b-tabs>
                                </b-modal>
                            </div> </b-col
                    ></b-row> </b-col
            ></b-row>
        </template>
    </sa-page-layout>
</template>

<script>
import moment from "moment";
import axios from "axios";
import DatePicker from "vue2-datepicker";
import PazienteAnagrafica from "./PazienteAnagrafica.vue";
import MisureList from "./MisureList.vue";
import LineChart from "./LineChart.js";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteDashboardComponent from "./PazienteDashboardComponent.vue";

export default {
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data() {
        return {
            pathRest: "/paziente",
            pathMisure: "/hyperhealth",
            rangeMisure: "/pazientemisurerange",
            loaded: false,
            ranges: [],
            errors: [],
            itemsRischio: [],
            arrayChart: [],
            dataSpo2: null,
            dataSonno: null,
            dataFreqCardiaca: null,
            dataPressioneTot: null,
            dataSteps: null,
            dataDistance: null,
            dataActive: null,
            dataCalories: null,
            dataTotalCalories: null,
            dataHrTot: null,
            selezioneDataIniziale: null,
            selezioneDataFinale: null,
            opzioniDataSonno: {
                horizontalLine: [],
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                userCallback: function(v) {
                                    return new Date(v * 1000).toISOString().substr(11, 5);
                                },
                                stepSize: 60 * 60,
                                suggestedMin: 10800,
                                suggestedMax: 39600,
                            },
                        },
                    ],
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            return data.datasets[tooltipItem.datasetIndex].label + ": " + new Date(tooltipItem.yLabel * 1000).toISOString().substr(12, 7);
                        },
                    },
                },
            },
            opzioniDataSpo: {
                responsive: true,
                maintainAspectRatio: true,
                horizontalLine: [],
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                stepSize: 20,
                                suggestedMin: 0,
                                suggestedMax: 100,
                            },
                        },
                    ],
                },
            },
            opzioniDataFreqCardiaca: {
                responsive: true,
                maintainAspectRatio: true,
                horizontalLine: [],
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                stepSize: 30,
                                suggestedMin: 0,
                                suggestedMax: 300,
                            },
                        },
                    ],
                },
            },
            opzioniDataPressioneTot: {
                responsive: true,
                maintainAspectRatio: true,
                horizontalLine: [],
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                stepSize: 30,
                                suggestedMin: 0,
                                suggestedMax: 240,
                            },
                        },
                    ],
                },
            },
            opzioniDataSteps: {
                responsive: true,
                maintainAspectRatio: true,
                horizontalLine: [],
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                stepSize: 3000,
                                suggestedMin: 0,
                                suggestedMax: 18000,
                            },
                        },
                    ],
                },
            },
            opzioniDataDistance: {
                responsive: true,
                maintainAspectRatio: true,
                horizontalLine: [],
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                stepSize: 3000,
                                suggestedMin: 0,
                                suggestedMax: 18000,
                            },
                        },
                    ],
                },
            },
            opzioniDataActive: {
                responsive: true,
                maintainAspectRatio: true,
                horizontalLine: [],
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                stepSize: 3000,
                                suggestedMin: 0,
                                suggestedMax: 30000,
                            },
                        },
                    ],
                },
            },
            opzioniDataTotalCalories: {
                responsive: true,
                maintainAspectRatio: true,
                horizontalLine: [],
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                stepSize: 500,
                                suggestedMin: 0,
                                suggestedMax: 5000,
                            },
                        },
                    ],
                },
            },
            opzioniDataCalories: {
                responsive: true,
                maintainAspectRatio: true,
                horizontalLine: [],
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                stepSize: 500,
                                suggestedMin: 0,
                                suggestedMax: 5000,
                            },
                        },
                    ],
                },
            },
            opzioniDataHrTot: {
                responsive: true,
                maintainAspectRatio: true,
                horizontalLine: [],
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                stepSize: 30,
                                suggestedMin: 0,
                                suggestedMax: 300,
                            },
                        },
                    ],
                },
            },
        };
    },
    components: {
        DatePicker,
        LineChart,
        PazienteAnagrafica,
        MisureList,
        SaPageLayout,
        PazienteDashboardComponent,
    },

    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.dateRange();
    },
    methods: {
        getRanges() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.rangeMisure + "?idPaziente=" + me.idPaziente;
            axios
                .get(link, { params: { filtro: {} } })
                .then((response) => {
                    me.ranges = response.data.data;
                    //console.log(me.items);
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        returnlabel(item) {
            switch (item) {
                case "dataFreqCardiaca":
                    return this.$i18n.t("patient.dashboard.lblBpmDescription");
                case "dataPressioneTot":
                    return this.$i18n.t("patient.dashboard.lblPressureDescription");
                case "dataSpo2":
                    return this.$i18n.t("patient.dashboard.lblSpo2Description");
                case "dataSonno":
                    return this.$i18n.t("patient.dashboard.lblSleepDescription");
                case "dataSteps":
                    return this.$i18n.t("patient.dashboard.lblStepsDescription");
                case "dataDistance":
                    return this.$i18n.t("patient.dashboard.lblDistanceDescription");
                case "dataActive":
                    return this.$i18n.t("patient.dashboard.lblActiveDescription");
                case "dataCalories":
                    return this.$i18n.t("patient.dashboard.lblCaloriesDescription");
                case "dataTotalCalories":
                    return this.$i18n.t("patient.dashboard.lblTotalCaloriesDescription");
                case "dataHrTot":
                    return this.$i18n.t("patient.dashboard.lblHeartRateDescription");
                case "ELENCO":
                    return this.$i18n.t("patient.dashboard.lblMeasurementIndex");
                default:
                    break;
            }
        },
        returnChart(item) {
            switch (item) {
                case "dataFreqCardiaca":
                    return this.dataFreqCardiaca;
                case "dataPressioneTot":
                    return this.dataPressioneTot;
                case "dataSpo2":
                    return this.dataSpo2;
                case "dataSonno":
                    return this.dataSonno;
                case "dataSteps":
                    return this.dataSteps;
                case "dataDistance":
                    return this.dataDistance;
                case "dataActive":
                    return this.dataActive;
                case "dataCalories":
                    return this.dataCalories;
                case "dataTotalCalories":
                    return this.dataTotalCalories;
                case "dataHrTot":
                    return this.dataHrTot;
                default:
                    break;
            }
        },
        returnOption(item) {
            switch (item) {
                case "dataFreqCardiaca":
                    return this.opzioniDataFreqCardiaca;
                case "dataPressioneTot":
                    return this.opzioniDataPressioneTot;
                case "dataSpo2":
                    return this.opzioniDataSpo;
                case "dataSonno":
                    return this.opzioniDataSonno;
                case "dataSteps":
                    return this.opzioniDataSteps;
                case "dataDistance":
                    return this.opzioniDataDistance;
                case "dataActive":
                    return this.opzioniDataActive;
                case "dataCalories":
                    return this.opzioniDataCalories;
                case "dataTotalCalories":
                    return this.opzioniDataTotalCalories;
                case "dataHrTot":
                    return this.opzioniDataHrTot;
                default:
                    break;
            }
        },
        loadData() {
            let me = this;
            let dataSelezionataIniziale = moment(me.selezioneDataIniziale).format("YYYY-MM-DD HH:mm:ss");
            let dataSelezionataFinale = moment(me.selezioneDataFinale).format("YYYY-MM-DD HH:mm:ss");
            let link = process.env.VUE_APP_PATH_API + me.pathMisure + "?idpaziente=" + me.idPaziente + "&dal=" + dataSelezionataIniziale + "&al=" + dataSelezionataFinale;
            axios
                .get(link)
                .then((response) => {
                    me.items = response.data.data;

                    let spoLabels = [];
                    let spoData = [];
                    let spoOrigin = [];
                    let spoDateList = [];

                    let sleepLabels = [];
                    let sleepData = [];
                    let sleepOrigin = [];
                    let sleepDateList = [];

                    let stepsLabels = [];
                    let stepsData = [];
                    let stepsOrigin = [];
                    let stepsDateList = [];

                    let distanceLabels = [];
                    let distanceData = [];
                    let distanceOrigin = [];
                    let distanceDateList = [];

                    let activeLabels = [];
                    let activeData = [];
                    let activeOrigin = [];
                    let activeDateList = [];

                    let caloriesLabels = [];
                    let caloriesData = [];
                    let caloriesOrigin = [];
                    let caloriesDateList = [];

                    let totalCaloriesLabels = [];
                    let totalCaloriesData = [];
                    let totalCaloriesOrigin = [];
                    let totalCaloriesDateList = [];

                    let freqCardiacaLabels = [];
                    let freqCardiacaData = [];
                    let freqCardiacaOrigin = [];
                    let freqCardiacaDateList = [];

                    let pressioneMinLabels = [];
                    let pressioneMinData = [];
                    let pressioneOrigin = [];
                    let pressioneDateList = [];

                    let pressioneMaxLabels = [];
                    let pressioneMaxData = [];

                    let hrMinLabels = [];
                    let hrMinData = [];
                    let hrMaxLabels = [];
                    let hrMaxData = [];
                    let hrAverageLabels = [];
                    let hrAverageData = [];
                    let hrTotOrigin = [];
                    let hrTotDateList = [];

                    for (let index = 0; index < me.items.length; index++) {
                        switch (me.items[index].measurename) {
                            case "SP02 (%)":
                                spoLabels.push(moment(me.items[index].istant).format("DD/MM HH:mm:ss"));
                                spoData.push(me.items[index].value);
                                spoOrigin.push(me.items[index].createUser);
                                spoDateList.push(me.items[index].istant);
                                break;
                            case "total_sleep_time":
                                sleepLabels.push(moment(me.items[index].istant).format("DD/MM HH:mm:ss"));
                                sleepData.push(me.items[index].value);
                                sleepOrigin.push(me.items[index].createUser);
                                sleepDateList.push(me.items[index].istant);
                                break;

                            case "Heart Pulse (bpm) - only for BPM devices":
                                freqCardiacaLabels.push(moment(me.items[index].istant).format("DD/MM HH:mm:ss"));
                                freqCardiacaData.push(me.items[index].value);
                                freqCardiacaOrigin.push(me.items[index].createUser);
                                freqCardiacaDateList.push(me.items[index].istant);
                                break;

                            case "Diastolic Blood Pressure (mmHg)":
                                pressioneMinLabels.push(moment(me.items[index].istant).format("DD/MM HH:mm:ss"));
                                pressioneMinData.push(me.items[index].value);
                                pressioneOrigin.push(me.items[index].createUser);
                                pressioneDateList.push(me.items[index].istant);
                                break;

                            case "Systolic Blood Pressure (mmHg)":
                                pressioneMaxLabels.push(moment(me.items[index].istant).format("DD/MM HH:mm:ss"));
                                pressioneMaxData.push(me.items[index].value);
                                break;

                            case "steps":
                                stepsLabels.push(moment(me.items[index].istant).format("DD/MM HH:mm:ss"));
                                stepsDateList.push(me.items[index].istant);
                                stepsData.push(me.items[index].value);
                                stepsOrigin.push(me.items[index].createUser);
                                break;

                            case "distance":
                                distanceLabels.push(moment(me.items[index].istant).format("DD/MM HH:mm:ss"));
                                distanceData.push(me.items[index].value);
                                distanceOrigin.push(me.items[index].createUser);
                                distanceDateList.push(me.items[index].istant);
                                break;

                            case "active":
                                activeLabels.push(moment(me.items[index].istant).format("DD/MM HH:mm:ss"));
                                activeData.push(me.items[index].value);
                                activeOrigin.push(me.items[index].createUser);
                                activeDateList.push(me.items[index].istant);
                                break;

                            case "calories":
                                caloriesLabels.push(moment(me.items[index].istant).format("DD/MM HH:mm:ss"));
                                caloriesData.push(me.items[index].value);
                                caloriesOrigin.push(me.items[index].createUser);
                                caloriesDateList.push(me.items[index].istant);
                                break;

                            case "totalcalories":
                                totalCaloriesLabels.push(moment(me.items[index].istant).format("DD/MM HH:mm:ss"));
                                totalCaloriesData.push(me.items[index].value);
                                totalCaloriesOrigin.push(me.items[index].createUser);
                                totalCaloriesDateList.push(me.items[index].istant);
                                break;

                            case "hr_min":
                                hrMinLabels.push(moment(me.items[index].istant).format("DD/MM HH:mm:ss"));
                                hrMinData.push(me.items[index].value);
                                break;

                            case "hr_max":
                                hrMaxLabels.push(moment(me.items[index].istant).format("DD/MM HH:mm:ss"));
                                hrMaxData.push(me.items[index].value);
                                break;

                            case "hr_average":
                                hrAverageLabels.push(moment(me.items[index].istant).format("DD/MM HH:mm:ss"));
                                hrAverageData.push(me.items[index].value);
                                hrTotOrigin.push(me.items[index].createUser);
                                hrTotDateList.push(me.items[index].istant);
                                break;
                        }
                    }
                    if (pressioneMinData[0] || pressioneMaxData[0]) {
                        if (!me.arrayChart.includes("dataPressioneTot")) {
                            me.arrayChart.push("dataPressioneTot");
                            // console.log(pressioneOrigin);
                        }

                        this.dataPressioneTot = {
                            labels: pressioneMinLabels,
                            datasets: [
                                {
                                    label: "Diastolic Blood Pressure (mmHg)",
                                    data: pressioneMinData,
                                    lineTension: 0,
                                    origin: pressioneOrigin,
                                    dateList: pressioneDateList,
                                    backgroundColor: "rgba(66, 136, 255, 0.2)",
                                    pointBorderColor: "rgba(66, 136, 255, 1)",
                                    bgColor: "'rgba(100, 0, 0, 0)'",
                                    dotColor: "'rgba(66, 136, 255, 1.8)'",
                                    borderColor: "'rgba(66, 136, 255, 1)'",
                                },
                                {
                                    label: "Systolic Blood Pressure (mmHg)",
                                    data: pressioneMaxData,
                                    lineTension: 0,

                                    backgroundColor: "rgba(66, 136, 255, 0.2)",
                                    pointBorderColor: "rgba(66, 136, 255, 1)",
                                    bgColor: "'rgba(100, 0, 0, 0)'",
                                    dotColor: "'rgba(66, 136, 255, 1.8)'",
                                    borderColor: "'rgba(66, 136, 255, 1)'",
                                },
                            ],
                        };
                        let minAlert = 0;
                        let maxAlert = 0;
                        // let minWarning = 0;
                        // let maxWarning = 0;
                        me.ranges.forEach((rischio) => {
                            if (rischio.misureNome === "Diastolic Blood Pressure (mmHg)") {
                                minAlert = rischio.minRangeAllert;
                                maxAlert = rischio.maxRangeAllert;
                                // minWarning = rischio.minRangeWarning;
                                // maxWarning = rischio.maxRangeWarning;
                                // this.opzioniDataPressioneTot.horizontalLine.push({ y: minWarning, style: "#ffc107", text: "Warning Diastolic" });
                                this.opzioniDataPressioneTot.horizontalLine.push({ y: minAlert, style: "#dc3545", text: "Alert Diastolic" });
                                this.opzioniDataPressioneTot.horizontalLine.push({ y: maxAlert, style: "#dc3545", text: "Alert Diastolic" });
                                // this.opzioniDataPressioneTot.horizontalLine.push({ y: maxWarning, style: "#ffc107", text: "Warning Diastolic" });
                            }
                            if (rischio.misureNome === "Systolic Blood Pressure (mmHg)") {
                                minAlert = rischio.minRangeAllert;
                                maxAlert = rischio.maxRangeAllert;
                                // minWarning = rischio.minRangeWarning;
                                // maxWarning = rischio.maxRangeWarning;
                                // this.opzioniDataPressioneTot.horizontalLine.push({ y: minWarning, style: "#ffc107", text: "Warning Systolic" });
                                this.opzioniDataPressioneTot.horizontalLine.push({ y: minAlert, style: "#dc3545", text: "Alert Systolic" });
                                this.opzioniDataPressioneTot.horizontalLine.push({ y: maxAlert, style: "#dc3545", text: "Alert Systolic" });
                                // this.opzioniDataPressioneTot.horizontalLine.push({ y: maxWarning, style: "#ffc107", text: "Warning Systolic" });
                            }
                        });
                        // console.log(this.dataPressioneTot);
                    }

                    if (hrMinData[0] || hrMaxData[0] || hrAverageData[0]) {
                        if (!me.arrayChart.includes("dataHrTot")) {
                            me.arrayChart.push("dataHrTot");
                            // console.log("dataHrTot");
                        }
                        this.dataHrTot = {
                            labels: hrMinLabels,
                            datasets: [
                                {
                                    label: "Hr Min",
                                    data: hrMinData,
                                    lineTension: 0,
                                    backgroundColor: "rgba(66, 136, 255, 0.2)",
                                    pointBorderColor: "rgba(66, 136, 255, 1)",
                                    bgColor: "'rgba(100, 0, 0, 0)'",
                                    dotColor: "'rgba(66, 136, 255, 1.8)'",
                                    borderColor: "'rgba(66, 136, 255, 1)'",
                                },
                                {
                                    label: "Hr Max",
                                    data: hrMaxData,
                                    lineTension: 0,

                                    backgroundColor: "rgba(66, 136, 255, 0.2)",
                                    pointBorderColor: "rgba(66, 136, 255, 1)",
                                    bgColor: "'rgba(100, 0, 0, 0)'",
                                    dotColor: "'rgba(66, 136, 255, 1.8)'",
                                    borderColor: "'rgba(66, 136, 255, 1)'",
                                },
                                {
                                    label: "Hr Average",
                                    data: hrAverageData,
                                    lineTension: 0,
                                    origin: hrTotOrigin,
                                    dateList: hrTotDateList,
                                    backgroundColor: "rgba(66, 136, 255, 0.2)",
                                    pointBorderColor: "rgba(66, 136, 255, 1)",
                                    bgColor: "'rgba(100, 0, 0, 0)'",
                                    dotColor: "'rgba(66, 136, 255, 1.8)'",
                                    borderColor: "'rgba(66, 136, 255, 1)'",
                                },
                            ],
                        };
                        let minAlert = 0;
                        let maxAlert = 0;
                        // let minWarning = 0;
                        // let maxWarning = 0;
                        me.ranges.forEach((rischio) => {
                            // if (rischio.misureNome === "hr_min") {
                            //   minAlert = rischio.minRangeAllert;
                            //   maxAlert = rischio.maxRangeAllert;
                            //   minWarning = rischio.minRangeWarning;
                            //   maxWarning = rischio.maxRangeWarning;
                            //   this.opzioniDataHrTot.horizontalLine.push({ y: minWarning, style: "#ffc107", text: "Warning Min" });
                            //   this.opzioniDataHrTot.horizontalLine.push({ y: minAlert, style: "#dc3545", text: "Alert Min" });
                            //   this.opzioniDataHrTot.horizontalLine.push({ y: maxAlert, style: "#dc3545", text: "Alert Min" });
                            //   this.opzioniDataHrTot.horizontalLine.push({ y: maxWarning, style: "#ffc107", text: "Warning Min" });
                            // }
                            if (rischio.misureNome === "hr_average") {
                                minAlert = rischio.minRangeAllert;
                                maxAlert = rischio.maxRangeAllert;
                                // minWarning = rischio.minRangeWarning;
                                // maxWarning = rischio.maxRangeWarning;
                                // this.opzioniDataHrTot.horizontalLine.push({ y: minWarning, style: "#ffc107", text: "Warning Average" });
                                this.opzioniDataHrTot.horizontalLine.push({ y: minAlert, style: "#dc3545", text: "Alert Average" });
                                this.opzioniDataHrTot.horizontalLine.push({ y: maxAlert, style: "#dc3545", text: "Alert Average" });
                                // this.opzioniDataHrTot.horizontalLine.push({ y: maxWarning, style: "#ffc107", text: "Warning Average" });
                            }
                        });
                    }
                    if (spoData[0]) {
                        if (!me.arrayChart.includes("dataSpo2")) {
                            me.arrayChart.push("dataSpo2");
                            // console.log("dataSpo2");
                        }
                        this.dataSpo2 = {
                            labels: spoLabels,
                            datasets: [
                                {
                                    label: "SP02 (%)",
                                    data: spoData,
                                    lineTension: 0,
                                    origin: spoOrigin,
                                    dateList: spoDateList,
                                    backgroundColor: "rgba(66, 136, 255, 0.2)",
                                    pointBorderColor: "rgba(66, 136, 255, 1)",
                                    bgColor: "'rgba(100, 0, 0, 0)'",
                                    dotColor: "'rgba(66, 136, 255, 1.8)'",
                                    borderColor: "'rgba(66, 136, 255, 1)'",
                                },
                            ],
                        };
                        let minAlert = 0;
                        let maxAlert = 0;
                        // let minWarning = 0;
                        // let maxWarning = 0;
                        me.ranges.forEach((rischio) => {
                            if (rischio.misureNome === "SP02 (%)") {
                                minAlert = rischio.minRangeAllert;
                                maxAlert = rischio.maxRangeAllert;
                                // minWarning = rischio.minRangeWarning;
                                // maxWarning = rischio.maxRangeWarning;
                                this.opzioniDataSpo = {
                                    responsive: true,
                                    maintainAspectRatio: true,
                                    horizontalLine: [
                                        // { y: minWarning, style: "#ffc107", text: "Warning" },
                                        { y: minAlert, style: "#dc3545", text: "Alert" },
                                        { y: maxAlert, style: "#dc3545", text: "Alert" },
                                        // { y: maxWarning, style: "#ffc107", text: "Warning" },
                                    ],
                                };
                            }
                        });
                    }
                    if (sleepData[0]) {
                        if (!me.arrayChart.includes("dataSonno")) {
                            me.arrayChart.push("dataSonno");
                            // console.log(sleepData);
                        }
                        this.dataSonno = {
                            labels: sleepLabels,
                            datasets: [
                                {
                                    label: "Sleep",
                                    data: sleepData,
                                    lineTension: 0,
                                    origin: sleepOrigin,
                                    dateList: sleepDateList,
                                    backgroundColor: "rgba(66, 136, 255, 0.2)",
                                    pointBorderColor: "rgba(66, 136, 255, 1)",
                                    bgColor: "'rgba(100, 0, 0, 0)'",
                                    dotColor: "'rgba(66, 136, 255, 1.8)'",
                                    borderColor: "'rgba(66, 136, 255, 1)'",
                                },
                            ],
                        };
                        let minAlert = 0;
                        let maxAlert = 0;
                        // let minWarning = 0;
                        // let maxWarning = 0;
                        me.ranges.forEach((rischio) => {
                            if (rischio.misureNome === "total_sleep_time") {
                                minAlert = rischio.minRangeAllert;
                                maxAlert = rischio.maxRangeAllert;
                                // minWarning = rischio.minRangeWarning;
                                // maxWarning = rischio.maxRangeWarning;
                                this.opzioniDataSonno.horizontalLine = [
                                    // { y: minWarning, style: "#ffc107", text: "Warning" },
                                    { y: minAlert, style: "#dc3545", text: "Alert" },
                                    { y: maxAlert, style: "#dc3545", text: "Alert" },
                                    // { y: maxWarning, style: "#ffc107", text: "Warning" },
                                ];
                            }
                        });
                    }
                    if (freqCardiacaData[0]) {
                        if (!me.arrayChart.includes("dataFreqCardiaca")) {
                            me.arrayChart.push("dataFreqCardiaca");
                            // console.log("dataFreqCardiaca");
                        }
                        this.dataFreqCardiaca = {
                            labels: freqCardiacaLabels,
                            datasets: [
                                {
                                    label: "Heart Pulse (bpm) - only for BPM devices",
                                    data: freqCardiacaData,
                                    lineTension: 0,
                                    origin: freqCardiacaOrigin,
                                    dateList: freqCardiacaDateList,
                                    backgroundColor: "rgba(66, 136, 255, 0.2)",
                                    pointBorderColor: "rgba(66, 136, 255, 1)",
                                    bgColor: "'rgba(100, 0, 0, 0)'",
                                    dotColor: "'rgba(66, 136, 255, 1.8)'",
                                    borderColor: "'rgba(66, 136, 255, 1)'",
                                },
                            ],
                        };
                        let minAlert = 0;
                        let maxAlert = 0;
                        // let minWarning = 0;
                        // let maxWarning = 0;
                        me.ranges.forEach((rischio) => {
                            if (rischio.misureNome === "Heart Pulse (bpm) - only for BPM devices") {
                                minAlert = rischio.minRangeAllert;
                                maxAlert = rischio.maxRangeAllert;
                                // minWarning = rischio.minRangeWarning;
                                // maxWarning = rischio.maxRangeWarning;
                                this.opzioniDataFreqCardiaca.horizontalLine = [
                                    // { y: minWarning, style: "#ffc107", text: "Warning" },
                                    { y: minAlert, style: "#dc3545", text: "Alert" },
                                    { y: maxAlert, style: "#dc3545", text: "Alert" },
                                    // { y: maxWarning, style: "#ffc107", text: "Warning" },
                                ];
                            }
                        });
                    }

                    if (stepsData[0]) {
                        if (!me.arrayChart.includes("dataSteps")) {
                            me.arrayChart.push("dataSteps");
                            // console.log("dataSteps");
                        }
                        this.dataSteps = {
                            labels: stepsLabels,
                            datasets: [
                                {
                                    label: "Number Of Steps",
                                    data: stepsData,
                                    lineTension: 0,
                                    origin: stepsOrigin,
                                    dateList: stepsDateList,
                                    backgroundColor: "rgba(66, 136, 255, 0.2)",
                                    pointBorderColor: "rgba(66, 136, 255, 1)",
                                    bgColor: "'rgba(100, 0, 0, 0)'",
                                    dotColor: "'rgba(66, 136, 255, 1.8)'",
                                    borderColor: "'rgba(66, 136, 255, 1)'",
                                },
                            ],
                        };
                        let minAlert = 0;
                        let maxAlert = 0;
                        // let minWarning = 0;
                        // let maxWarning = 0;
                        me.ranges.forEach((rischio) => {
                            if (rischio.misureNome === "steps") {
                                minAlert = rischio.minRangeAllert;
                                maxAlert = rischio.maxRangeAllert;
                                // minWarning = rischio.minRangeWarning;
                                // maxWarning = rischio.maxRangeWarning;
                                this.opzioniDataSteps.horizontalLine = [
                                    // { y: minWarning, style: "#ffc107", text: "Warning" },
                                    { y: minAlert, style: "#dc3545", text: "Alert" },
                                    { y: maxAlert, style: "#dc3545", text: "Alert" },
                                    // { y: maxWarning, style: "#ffc107", text: "Warning" },
                                ];
                            }
                        });
                    }
                    if (distanceData[0]) {
                        if (!me.arrayChart.includes("dataDistance")) {
                            me.arrayChart.push("dataDistance");
                            // console.log("dataDistance");
                        }
                        this.dataDistance = {
                            labels: distanceLabels,
                            datasets: [
                                {
                                    label: "Distance",
                                    data: distanceData,
                                    lineTension: 0,
                                    origin: distanceOrigin,
                                    dateList: distanceDateList,
                                    backgroundColor: "rgba(66, 136, 255, 0.2)",
                                    pointBorderColor: "rgba(66, 136, 255, 1)",
                                    bgColor: "'rgba(100, 0, 0, 0)'",
                                    dotColor: "'rgba(66, 136, 255, 1.8)'",
                                    borderColor: "'rgba(66, 136, 255, 1)'",
                                },
                            ],
                        };
                        let minAlert = 0;
                        let maxAlert = 0;
                        // let minWarning = 0;
                        // let maxWarning = 0;
                        me.ranges.forEach((rischio) => {
                            if (rischio.misureNome === "distance") {
                                minAlert = rischio.minRangeAllert;
                                maxAlert = rischio.maxRangeAllert;
                                // minWarning = rischio.minRangeWarning;
                                // maxWarning = rischio.maxRangeWarning;
                                this.opzioniDataDistance.horizontalLine = [
                                    // { y: minWarning, style: "#ffc107", text: "Warning" },
                                    { y: minAlert, style: "#dc3545", text: "Alert" },
                                    { y: maxAlert, style: "#dc3545", text: "Alert" },
                                    // { y: maxWarning, style: "#ffc107", text: "Warning" },
                                ];
                            }
                        });
                    }
                    if (activeData[0]) {
                        if (!me.arrayChart.includes("dataActive")) {
                            me.arrayChart.push("dataActive");
                            // console.log(activeData);
                        }
                        this.dataActive = {
                            labels: stepsLabels,
                            datasets: [
                                {
                                    label: "Active",
                                    data: activeData,
                                    lineTension: 0,
                                    origin: activeOrigin,
                                    dateList: activeDateList,
                                    backgroundColor: "rgba(66, 136, 255, 0.2)",
                                    pointBorderColor: "rgba(66, 136, 255, 1)",
                                    bgColor: "'rgba(100, 0, 0, 0)'",
                                    dotColor: "'rgba(66, 136, 255, 1.8)'",
                                    borderColor: "'rgba(66, 136, 255, 1)'",
                                },
                            ],
                        };
                        let minAlert = 0;
                        let maxAlert = 0;
                        // let minWarning = 0;
                        // let maxWarning = 0;
                        me.ranges.forEach((rischio) => {
                            if (rischio.misureNome === "active") {
                                minAlert = rischio.minRangeAllert;
                                maxAlert = rischio.maxRangeAllert;
                                // minWarning = rischio.minRangeWarning;
                                // maxWarning = rischio.maxRangeWarning;
                                this.opzioniDataActive.horizontalLine = [
                                    // { y: minWarning, style: "#ffc107", text: "Warning" },
                                    { y: minAlert, style: "#dc3545", text: "Alert" },
                                    { y: maxAlert, style: "#dc3545", text: "Alert" },
                                    // { y: maxWarning, style: "#ffc107", text: "Warning" },
                                ];
                            }
                        });
                    }
                    if (caloriesData[0]) {
                        if (!me.arrayChart.includes("dataCalories")) {
                            me.arrayChart.push("dataCalories");
                            // console.log("dataCalories");
                        }
                        this.dataCalories = {
                            labels: distanceLabels,
                            datasets: [
                                {
                                    label: "Calories",
                                    data: caloriesData,
                                    lineTension: 0,
                                    origin: caloriesOrigin,
                                    dateList: activeDateList,
                                    backgroundColor: "rgba(66, 136, 255, 0.2)",
                                    pointBorderColor: "rgba(66, 136, 255, 1)",
                                    bgColor: "'rgba(100, 0, 0, 0)'",
                                    dotColor: "'rgba(66, 136, 255, 1.8)'",
                                    borderColor: "'rgba(66, 136, 255, 1)'",
                                },
                            ],
                        };
                        let minAlert = 0;
                        let maxAlert = 0;
                        // let minWarning = 0;
                        // let maxWarning = 0;
                        me.ranges.forEach((rischio) => {
                            if (rischio.misureNome === "distance") {
                                minAlert = rischio.minRangeAllert;
                                maxAlert = rischio.maxRangeAllert;
                                // minWarning = rischio.minRangeWarning;
                                // maxWarning = rischio.maxRangeWarning;
                                this.opzioniDataCalories.horizontalLine = [
                                    // { y: minWarning, style: "#ffc107", text: "Warning" },
                                    { y: minAlert, style: "#dc3545", text: "Alert" },
                                    { y: maxAlert, style: "#dc3545", text: "Alert" },
                                    // { y: maxWarning, style: "#ffc107", text: "Warning" },
                                ];
                            }
                        });
                    }
                    if (totalCaloriesData[0]) {
                        if (!me.arrayChart.includes("dataTotalCalories")) {
                            me.arrayChart.push("dataTotalCalories");
                            // console.log("dataTotalCalories");
                        }
                        this.dataTotalCalories = {
                            labels: totalCaloriesLabels,
                            datasets: [
                                {
                                    label: "Total Calories",
                                    data: totalCaloriesData,
                                    lineTension: 0,
                                    origin: totalCaloriesOrigin,
                                    dateList: totalCaloriesDateList,
                                    backgroundColor: "rgba(66, 136, 255, 0.2)",
                                    pointBorderColor: "rgba(66, 136, 255, 1)",
                                    bgColor: "'rgba(100, 0, 0, 0)'",
                                    dotColor: "'rgba(66, 136, 255, 1.8)'",
                                    borderColor: "'rgba(66, 136, 255, 1)'",
                                },
                            ],
                        };
                        let minAlert = 0;
                        let maxAlert = 0;
                        // let minWarning = 0;
                        // let maxWarning = 0;
                        me.ranges.forEach((rischio) => {
                            if (rischio.misureNome === "totalcalories") {
                                minAlert = rischio.minRangeAllert;
                                maxAlert = rischio.maxRangeAllert;
                                // minWarning = rischio.minRangeWarning;
                                // maxWarning = rischio.maxRangeWarning;
                                this.opzioniDataTotalCalories.horizontalLine = [
                                    // { y: minWarning, style: "#ffc107", text: "Warning" },
                                    { y: minAlert, style: "#dc3545", text: "Alert" },
                                    { y: maxAlert, style: "#dc3545", text: "Alert" },
                                    // { y: maxWarning, style: "#ffc107", text: "Warning" },
                                ];
                            }
                        });
                    }

                    this.loaded = true;
                })
                .catch((e) => {
                    me.errors.push(e);
                });
        },
        onSubmit() {
            let me = this;
            me.loadData();
        },
        dateRange() {
            let me = this;
            me.selezioneDataIniziale = new Date().setDate(new Date().getDate() - 7);
            me.selezioneDataFinale = Date.now();
            me.getRanges();
            me.loadData();
        },
        onBack() {
            let me = this;
            me.$router.replace(me.pathRest + "/view/" + me.id).catch((err) => {
                err;
            });
        },
    },
};
</script>

<style>
.small {
    max-width: 600px;
    /*margin: 150px auto;*/
}
</style>
