<template>
  <sa-page-layout :btnNewVisible="false" :btnRefreshVisible="true" @refresh="onRefresh">
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="xl" striped hover :items="items" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="item">
            {{ item.index + 1 }}
          </template>
          <template #cell(delete)="row">
            <b-button variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" size="sm" @click="onDeleteItem(row.item)"><b-icon icon="trash"></b-icon></b-button>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import axios from "axios";
export default {
  components: { SaPageLayout },
  mounted() {
    let me = this;
    me.loadData();
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      pathResource: "hassistohubs",
      items: [],
      fields: [
        {
          label: "",
          key: "index",
          thStyle: "width: 1rem",
          tdClass: "text-center",
        },
        {
          label: "Hub Cod",
          key: "hubUUID",
          thStyle: "width: 9rem",
          tdClass: "text-left",
          sortable: true,
        },
        {
          label: "Name",
          key: "hubName",
          thStyle: "width: 9rem",
          tdClass: "text-left",
          sortable: true,
        },
        {
          label: "",
          key: "delete",
          thStyle: "width: 1rem",
          tdClass: "text-center",
        },
      ],
    };
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/" + me.pathResource;
      axios
        .get(link)
        .then((Response) => {
          me.items = Response.data.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },

    onDeleteItem(item) {
      let me = this;
      this.$bvModal
        .msgBoxConfirm("Sei sicuro di procedere alla cancellazione?", {
          title: "Conferma Cancellazione",
          buttonSize: "sm",
          okVariant: "secondary",
          cancelVariant: "danger",
          okTitle: "SI",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            me.deleteItem(item);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    deleteItem(item) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/" + me.pathResource;
      axios
        .delete(link, { data: { hubUUID: item.hubUUID } })
        .then((response) => {
          console.log(response);
          me.modalShow = false;
          me.loadData();
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
