<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" :btnBackVisible="false" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h5 class="sa-text-left">Dati</h5>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-row>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.diabetology.lblHeight") }}</strong>
            <br />
            <b-form-input v-model="jsonData.altezza" placeholder="Altezza" type="number" @input="onCalcoloBmi(jsonData.altezza, jsonData.peso)"></b-form-input>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.diabetology.lblWeight") }}</strong>
            <br />
            <b-form-input v-model="jsonData.peso" placeholder="Peso" type="number" @input="onCalcoloBmi(jsonData.altezza, jsonData.peso)"></b-form-input>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4">
            <strong>B.M.I</strong>
            <br />
            <b-form-input v-model="jsonData.bmi" type="number" placeholder="B.M.I" readonly></b-form-input>
          </b-col>
        </b-row>
        <hr />

        <b-row>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong> {{ this.$i18n.t("patient.diabetology.lblIdealWeight") }}</strong>
            <br />
            <b-form-input type="number" v-model="jsonData.pesoIdeale" placeholder="Peso Ideale"></b-form-input>
          </b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong>{{ this.$i18n.t("patient.diabetology.lblIdealWeightAbove") }}</strong>
            <br />
            <b-form-input type="number" v-model="jsonData.pesoPesoIdealeSopra" placeholder="Peso Ideale Sopra"></b-form-input>
          </b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong>{{ this.$i18n.t("patient.diabetology.lblIdealWeightBelow") }} </strong>
            <br />
            <b-form-input type="number" v-model="jsonData.pesoPesoIdealeSotto" placeholder="Peso Ideale Sotto"></b-form-input>
          </b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong> Spo2 </strong>
            <br />
            <div>
              <b-input-group class="mb-3">
                <b-form-input v-model="jsonData.spo2" placeholder="Spo2"></b-form-input>
                <b-input-group-append>
                  <b-button size="sm" text="Button" variant="secondary" v-b-modal.modalSpo2><b-icon icon="search"></b-icon></b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <strong> {{ this.$i18n.t("patient.diabetology.lblSystolicPressure") }}</strong>
            <br />
            <b-input-group class="mb-3">
              <b-form-input v-model="jsonData.pressioneSistolica" placeholder="Pressione Massima"></b-form-input>
              <b-input-group-append>
                <b-button size="m" text="Button" variant="secondary" v-b-modal.modalPressione><b-icon icon="search"></b-icon></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <strong> {{ this.$i18n.t("patient.diabetology.lblDiastolicBloodPressure") }}</strong>
            <br />
            <b-input-group class="mb-3">
              <b-form-input v-model="jsonData.pressioneDiastolica" placeholder="Pressione Minima" readonly></b-form-input>
            </b-input-group>
          </b-col>
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <strong> {{ this.$i18n.t("patient.diabetology.lblHeartRate") }}</strong>
            <br />
            <b-input-group class="mb-3">
              <b-form-input v-model="jsonData.frequenzaCardiaca" placeholder="Frequenza Cardiaca"></b-form-input>
              <b-input-group-append>
                <b-button size="sm" text="Button" variant="secondary" v-b-modal.modalFrequenza><b-icon icon="search"></b-icon></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4">
            <strong> {{ this.$i18n.t("patient.diabetology.lblSkinAndAnnexes") }}</strong>
            <br />
            <b-form-textarea v-model="jsonData.cuteEdAnnessi" placeholder="Cute ed Annessi" no-resize></b-form-textarea>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.diabetology.lblRespiratorySystem") }}</strong>
            <br />
            <b-form-textarea v-model="jsonData.apparatoRespiratorio" placeholder="Respiratorio" no-resize></b-form-textarea>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.lblDigestiveSystem") }}</strong>
            <br />
            <b-form-textarea v-model="jsonData.apparatoDigerente" placeholder="Digerente" no-resize></b-form-textarea>
          </b-col>
        </b-row>

        <hr />
        <b-row>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patients.lblUrogenitalApparatus") }} </strong>
            <br />
            <b-form-textarea v-model="jsonData.apparatoUrogenitale" placeholder="Urogenitale" no-resize></b-form-textarea>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.diabetology.lblArticularMuscleSystem") }} </strong>
            <br />
            <b-form-textarea v-model="jsonData.apparatoMuscoloArticolare" placeholder="Muscolo Articolare" no-resize></b-form-textarea>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.diabetology.lblCardiovascularSystem") }} </strong>
            <br />
            <b-form-textarea v-model="jsonData.sistemaCardiovascolare" placeholder="Cardiovascolare" no-resize> </b-form-textarea>
          </b-col>
        </b-row>
        <hr />

        <b-row>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong>{{ this.$i18n.t("patients.lblNervousSystem") }}</strong>
            <br />
            <b-form-textarea v-model="jsonData.sistemaNervoso" placeholder="Sistema Nervoso" no-resize></b-form-textarea>
          </b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong> {{ this.$i18n.t("patients.lblFoot") }}</strong>
            <br />
            <b-form-textarea v-model="jsonData.piede" placeholder="Piede" no-resize></b-form-textarea>
          </b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="43">
            <strong> {{ this.$i18n.t("patients.lblSubcutaneous") }}</strong>
            <br />
            <b-form-textarea v-model="jsonData.sottocutaneo" placeholder="Sotto Cutaneo" no-resize></b-form-textarea>
          </b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong> {{ this.$i18n.t("patients.lblMouthTeeth") }}</strong>
            <br />
            <b-form-textarea v-model="jsonData.boccaDenti" placeholder="Sotto Cutaneo" no-resize></b-form-textarea>
          </b-col>
        </b-row>
      </div>
      <hr />
      <b-modal id="modalPressione" title="Diastolica" size="l" hide-footer v-model="showmodalPressione">
        <b-table :select-mode="'single'" selectable @row-selected="onRowSelected" sticky-header ref="table" stacked="l" striped hover :items="misurazioniPressione" :fields="fieldsPressione" sort-icon-left head-variant="light" class="sa-b-table"> </b-table>
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template>
        </template>
        <div class="sa-padding-right text-right col">
          <b-button size="sm" variant="danger" @click="showmodalPressione = false">
            Cancel
          </b-button>
          <b-button size="sm" variant="secondary" @click="onAggiungiDs">
            OK
          </b-button>
        </div>
      </b-modal>
      <b-modal id="modalFrequenza" title="Frequenza Cardiaca" size="lg" hide-footer v-model="showModalFrequenza">
        <b-table :select-mode="'single'" selectable @row-selected="onRowSelected" sticky-header ref="table" stacked="l" striped hover :items="misurazioneCardiaca" :fields="fieldsFrequenza" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
        <div class="sa-padding-right text-right col">
          <b-button size="sm" variant="danger" @click="showModalFrequenza = false">
            Cancel
          </b-button>
          <b-button size="sm" variant="secondary" @click="onAggiungiFrequenza">
            OK
          </b-button>
        </div>
      </b-modal>
      <b-modal id="modalSpo2" title="SPO2" size="l" hide-footer v-model="showmodalSpo2">
        <b-table :select-mode="'single'" selectable @row-selected="onRowSelected" sticky-header ref="table" stacked="l" striped hover :items="misurazioneSpo2" :fields="fieldsSpo2" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
        <hr />
        <b-row>
          <div class="sa-padding-right text-right col">
            <b-button size="sm" variant="danger" @click="showmodalSpo2 = false">
              Cancel
            </b-button>
            <b-button size="sm" variant="secondary" @click="onAggiungiSpo">
              OK
            </b-button>
          </div>
        </b-row>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import axios from "axios";
import moment from "moment";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../../utility/UtilityMixin";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { SaPageLayout, PazienteAnagrafica },
  data() {
    return {
      linkback: null,
      pathResource: "/diabetologiaprimavisita",
      pathMisurazioni: "/pazientemisure",
      showModalLoading: false,
      showModalFrequenza: false,
      showmodalSpo2: false,
      showmodalPressione: false,
      currentPage: 1,
      perPage: 10,
      id: "-1",
      profili: [],
      misurazioneCardiaca: [],
      misurazioniPressione: [],
      misurazioneSpo2: [],
      jsonData: { idPaziente: this.idPaziente },
      provenienzaOption: [{ value: null, text: null }],
      dataInizio: "2021-11-22 00:00:00",
      dataFine: "2021-11-22 23:00:00",
      selected: {},
      fieldsFrequenza: [
        {
          label: "",
          key: "selected",
          selection: true,
          thStyle: "width: 1rem",
        },
        {
          label: "Frequenza Cardiaca",
          key: "value",
          sortable: true,
        },
        {
          label: "Data Esecuzione",
          key: "istant",
          sortable: true,
          formatter: (value) => {
            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
          },
        },
        {
          label: "",
          key: "action",
          thStyle: "width: 4rem",
        },
      ],
      fieldsSpo2: [
        {
          label: "",
          key: "selected",
          selection: true,
          thStyle: "width: 1rem",
        },
        {
          label: "Valore SPO2",
          key: "value",
          sortable: true,
        },
        {
          label: "Data Esecuzione",
          key: "istant",
          sortable: true,
          formatter: (value) => {
            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
          },
        },
      ],
      fieldsPressione: [
        {
          label: "",
          key: "selected",
          selection: true,
          thStyle: "width: 1rem",
        },
        {
          label: "data evento",
          key: "data",
          sortable: true,
        },
        {
          label: "pressureMax",
          key: "pressioneMax",
          sortable: true,
        },
        {
          label: "pressureMin",
          key: "pressioneMin",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    dataEventoPlaceholder() {
      return this.$i18n.t("patient.events.lblEventDate");
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo.menuGruppo) {
          if (this.utente.gruppo.menuGruppo.length > 1) {
            return "view/" + this.idPaziente;
          } else {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "view/" + this.idPaziente;
              } else {
                return "homepaziente";
              }
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    },
  },
  mounted: function() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Diabetologia - Prima Visita";
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/diabetologiaprimavisita/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    if (me.id !== "-1") {
      me.linkback = "/paziente/diabetologiaprimavisita/view/" + me.id + "?idPaziente=" + me.idPaziente;
      me.loadData();
    } else {
      me.linkback = "/paziente/diabetologiaprimavisita/?idPaziente=" + me.idPaziente;
    }
    me.loadMisurazioni();
  },
  methods: {
    onRowSelected(items) {
      let me = this;
      me.selected = items;
    },
    onCalcoloBmi(altezza, peso) {
      let me = this;
      me.jsonData.bmi = (peso / ((altezza / 100) * (altezza / 100))).toFixed(2);
    },
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
      } else {
        return "---";
      }
    },
    onAggiungiFrequenza() {
      let me = this;
      me.jsonData.frequenzaCardiaca = me.selected[0].value;
      me.showModalFrequenza = false;
    },
    onAggiungiDs() {
      let me = this;
      me.jsonData.pressioneSistolica = me.selected[0].pressioneMax;
      me.jsonData.pressioneDiastolica = me.selected[0].pressioneMin;
      me.showmodalPressione = false;
    },
    onAggiungiSpo() {
      let me = this;
      me.jsonData.spo2 = me.selected[0].value;
      me.showmodalSpo2 = false;
    },
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.id).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
    controllText(value, text) {
      if (value == false) {
        text = "";
        return text;
      }
    },

    loadMisurazioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathMisurazioni + "?idpaziente=" + me.idPaziente + "&dal=" + me.dataInizio + "&al=" + me.dataFine;
      me.showModalLoading = false;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          if (element.measurename == "pressureMax" || element.measurename == "pressureMin") {
            //  if(element.istant)
            me.addPressure(element);
            //me.misurazioniPressione.push(element);
          } else if (element.measurename == "heartRate") {
            me.misurazioneCardiaca.push(element);
          } else if (element.measurename == "spo2") {
            me.misurazioneSpo2.push(element);
          }
        });
        me.showModalLoading = false;
      });
    },
    addPressure(misura) {
      let me = this;
      let dataMisura = me.formatDate(misura.istant);
      let present = false;
      me.misurazioniPressione.forEach((misurazione) => {
        if (misurazione.data === dataMisura) {
          present = true;
          if (misura.measurename === "pressureMax") {
            misurazione.pressioneMax = misura.value;
          }
          if (misura.measurename === "pressureMin") {
            misurazione.pressioneMin = misura.value;
          }
        }
      });

      if (!present) {
        let misurazione = { data: dataMisura };
        if (misura.measurename === "pressureMax") {
          misurazione.pressioneMax = misura.value;
        }
        if (misura.measurename === "pressureMin") {
          misurazione.pressioneMin = misura.value;
        }
        me.misurazioniPressione.push(misurazione);
      }
    },
  },
};
</script>
