<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" :btnBackVisible="false" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h5 class="sa-text-left">Visita EGDS</h5>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <div class="sa-form-section">
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <span>Provenienza </span>
              <br />
              <b-form-select v-model="jsonData.provenienza" :options="provenienzaOption" text-field="text" value-field="value" :value="null"></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <span>Infermiere</span>
              <b-form-textarea v-model="jsonData.referto" no-resize></b-form-textarea>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <span>Referto</span>
              <b-form-select v-model="jsonData.infermiere" :options="infermiereOption" :value="null"></b-form-select>
            </b-col>
          </b-row>
        </div>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
export default {
  components: { SaPageLayout, PazienteAnagrafica },

  data() {
    return {
      jsonData: {},
      infermiereOption: [{ text: "Non Specificato", value: null }],
      provenienzaOption: [
        { text: "Non Specificato", value: null },
        { text: "Reparto", value: "reparto" },
        { text: "Altra Asl", value: "Altra Asl" },
        { text: "Esterno", value: "Esterno" },
      ],
    };
  },
};
</script>
