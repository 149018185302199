<template>
    <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" :btnBackVisible="false" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-header">
            <h5 class="sa-text-left">Dati</h5>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <div class="sa-form-section">
                    <template>
                        <div>
                            <div class="sa-form-section">
                                <b-row>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <strong>{{ this.$i18n.t("patient.diabetology.lblHeight") }}</strong>
                                        <br />
                                        <b-form-input type="number" v-model="jsonData.altezza"></b-form-input>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <strong>{{ this.$i18n.t("patient.diabetology.lblWeight") }}</strong>
                                        <br />
                                        <b-form-input type="number" v-model="jsonData.peso"></b-form-input>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <strong>{{ this.$i18n.t("patient.diabetology.lblBloodPressure") }}</strong>
                                        <br />
                                        <b-form-input type="number" v-model="jsonData.pressioneArteriosa"></b-form-input>
                                    </b-col>
                                </b-row>
                            </div>
                            <hr />
                            <div class="sa-form-section">
                                <b-row>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <strong>{{ this.$i18n.t("patient.diabetology.lblHeartRate") }}</strong>
                                        <br />
                                        <b-form-input type="number" v-model="jsonData.frequenzaCardiaca"></b-form-input>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <strong>{{ this.$i18n.t("patient.diabetology.lblOutpatientBloodSugar") }}</strong>
                                        <br />
                                        <b-form-input type="number" v-model="jsonData.glicemiaAmbulatoriale"></b-form-input>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <strong>{{ this.$i18n.t("patient.diabetology.lblKetonuria") }}</strong>
                                        <br />
                                        <b-form-input type="number" v-model="jsonData.chetonuria"></b-form-input>
                                    </b-col>
                                </b-row>
                            </div>
                            <hr />

                            <div class="sa-form-section">
                                <b-row>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <strong>{{ this.$i18n.t("patient.diabetology.lblFructosamine") }}</strong>
                                        <br />
                                        <b-form-input type="number" v-model="jsonData.fruttosamina"></b-form-input>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <strong>{{ this.$i18n.t("patient.diabetology.lblGlycosylatedHemoglobin") }}</strong>
                                        <br />
                                        <b-form-input type="number" v-model="jsonData.emoglobinaGlicosilata"></b-form-input>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <strong>{{ this.$i18n.t("patient.diabetology.lblCalorieDiet") }}</strong>
                                        <br />
                                        <b-form-input type="number" v-model="jsonData.dietaKcal"></b-form-input>
                                    </b-col>
                                </b-row>
                            </div>
                            <hr />

                            <div class="sa-form-section">
                                <b-row>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <strong>{{ this.$i18n.t("patient.diabetology.lblHoursFromMeal") }}</strong>
                                        <br />
                                        <b-form-input type="number" v-model="jsonData.oreDalPasto"></b-form-input>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <strong>{{ this.$i18n.t("patient.diabetology.lblGlycemia") }}</strong>
                                        <br />
                                        <b-form-input type="number" v-model="jsonData.glicemia"></b-form-input>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <strong>{{ this.$i18n.t("patient.diabetology.lblFastingBloodGlucose") }}</strong>
                                        <br />
                                        <b-form-input type="number" v-model="jsonData.glicemiaADigiuno"></b-form-input>
                                    </b-col>
                                </b-row>
                            </div>
                            <hr />
                            <div class="sa-form-section">
                                <b-row>
                                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                        <strong>{{ this.$i18n.t("patient.diabetology.lblGlycemiaBeforeLunch") }}</strong>
                                        <br />
                                        <b-form-input type="number" v-model="jsonData.glicemiaPrimaPranzo"></b-form-input>
                                    </b-col>

                                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                        <strong>{{ this.$i18n.t("patient.diabetology.lblBloodSugarTwoHoursAfterLunch") }}</strong>
                                        <br />
                                        <b-form-input type="number" v-model="jsonData.glicemiaDueOreDopoPranzo"></b-form-input>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                        <strong>{{ this.$i18n.t("patient.diabetology.lblGlycemiaBeforeSupper") }}</strong>
                                        <br />
                                        <b-form-input type="number" v-model="jsonData.glicemiaPrimaCena"></b-form-input>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                        <strong>{{ this.$i18n.t("patient.diabetology.lblbloodSugarTwoHoursAfterDinner") }}</strong>
                                        <br />
                                        <b-form-input type="number" v-model="jsonData.glicemiaDueOreDopoCena"></b-form-input>
                                    </b-col>
                                </b-row>
                            </div>
                            <hr />
                            <div class="sa-form-section">
                                <b-row>
                                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                        <strong>{{ this.$i18n.t("patient.diabetology.lblhypoglycemiaWeek") }}</strong>
                                        <br />
                                        <b-form-input type="number" v-model="jsonData.ipoglicemieSettimana"></b-form-input>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                        <strong>{{ this.$i18n.t("patient.diabetology.lblHypoglycemicTherapy") }}</strong>
                                        <br />
                                        <b-form-input type="text" v-model="jsonData.terapiaIpoglicemica"></b-form-input>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                        <strong>{{ this.$i18n.t("patient.diabetology.lblHypoglycemicSchedule") }}</strong>
                                        <br />
                                        <date-picker format="HH:mm" value-type="timestamp" v-model="jsonData.orarioIpoglicemie" type="time"></date-picker>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                        <strong>{{ this.$i18n.t("patient.diabetology.lblOtherMedicines") }}</strong>
                                        <br />
                                        <b-form-input type="text" v-model="jsonData.altriFarmaci"></b-form-input>
                                    </b-col>
                                </b-row>
                            </div>
                            <hr />
                            <b-row>
                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <div class="sa-view-form-data"><user-information :data="jsonData"></user-information></div>
                                </b-col>
                            </b-row>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UserInformation from "../../../../utenti/components/UserInformation.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import axios from "axios";
export default {
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    components: { PazienteAnagrafica, SaPageLayout, UserInformation, DatePicker },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient: {
            get: function() {
                if (this.utente.gruppo.menuGruppo) {
                    if (this.utente.gruppo.menuGruppo.length > 1) {
                        return "/paziente/view/" + this.idPaziente;
                    } else {
                        if (this.utente.gruppo.menuGruppo.length == 1) {
                            if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                return "/paziente/view/" + this.idPaziente;
                            } else {
                                return "/paziente/homepaziente";
                            }
                        } else {
                            return "";
                        }
                    }
                } else {
                    return "";
                }
            },
        },
    },
    data() {
        return {
            linkback: null,
            pathResource: "/diabetologiafollowup",
            jsonData: {},
            showModalLoading: false,
        };
    },
    mounted() {
        let me = this;
        me.jsonData.idPaziente = this.$route.query.idPaziente;
        me.jsonData.tipoEvento = "Diabetologia - Follow Up";
        me.id = this.$route.params.id;
        me.linkedit = "/paziente/diabetologia/followup/edit/" + me.id + "?idPaziente=" + me.idPaziente;
        if (me.id !== "-1") {
            me.linkback = "/paziente/diabetologia/followup/view/" + me.id + "?idPaziente=" + me.idPaziente;
            me.loadData();
        } else {
            me.linkback = "/paziente/diabetologia/followup?idPaziente=" + me.idPaziente;
        }
    },
    methods: {
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                });
            }
        },
    },
};
</script>
