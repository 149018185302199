<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" :btnBackVisible="false" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h5 class="sa-text-left">Dati</h5>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <!-- InizioBody  -->
        <h6 class="sa-text-left">{{ this.$i18n.t("menu.pazienti.gastroenterology.anamnesis") }}</h6>
        <div class="sa-form-section">
          <b-row>
            <b-col xs="2" sm="2" md="6" lg="6" xl="6">
              <strong>{{ this.$i18n.t("patient.gastroenterology.lblDate") }}</strong>
              <br />
              <span>{{ jsonData.createDate }}</span>
            </b-col>

            <b-col xs="10" sm="10" md="6" lg="6" xl="6"> </b-col>
          </b-row>
          <hr />
        </div>
        <div class="sa-form-section">
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <strong>{{ this.$i18n.t("patients.lblRemotePathologicalAnamnesis") }}</strong>
              <br />
              <span>{{ jsonData.anamnesiPatologicaRemota }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <strong>{{ this.$i18n.t("patients.lblNextPathologicalAnamnesis") }}</strong>
              <br />
              <span>{{ jsonData.anamnesiPatologicaProssima }}</span>
            </b-col>
          </b-row>
        </div>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: { SaPageLayout, PazienteAnagrafica },
  data() {
    return {
      linkback: null,
      pathResource: "/gastroanamnesipatologica",
      jsonData: {},
      showModalLoading: false,
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo.menuGruppo) {
          if (this.utente.gruppo.menuGruppo.length > 1) {
            return "/paziente/view/" + this.idPaziente;
          } else {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "/paziente/view/" + this.idPaziente;
              } else {
                return "/paziente/homepaziente";
              }
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Gastroenterologia - Anamnesi Patologica";
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/gastroenterologia/anamnesipatologica/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    if (me.id !== "-1") {
      me.linkback = "/paziente/gastroenterologia/anamnesipatologica?idPaziente=" + me.idPaziente;
      me.loadData();
    } else {
      me.linkback = "/paziente/gastroenterologia/anamnesipatologica?idPaziente=" + me.idPaziente;
    }
  },

  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
  },
};
</script>
