<template>
  <sa-page-layout :btnRefreshVisible="btnRefreshVisible" :btnNewVisible="btnNewVisible" @refresh="onRefresh" :linkedit="linkedit">
    <!--<template slot="toolbar">
            <b-button class="float-sm-right " variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus"></b-icon>{{ this.$i18n.t("global.lblBtnNew") }}</b-button>
            <b-button class="float-sm-right " variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" />Refresh</b-button>
        </template>-->
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col sm="6">
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataDal" type="datetime" placeholder="Data Dal"></date-picker>
          </b-col>
          <b-col sm="6">
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataAl" type="datetime" placeholder="Data Al"></date-picker>
          </b-col>
        </b-row>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col sm="6">
            <b-form-input v-model="filtro.numeroDocumento" type="search" id="numeroDocumento" placeholder="Numero Documento"></b-form-input>
          </b-col>
          <b-col sm="6">
            <b-form-input v-model="filtro.tipoDocumentoDescrizione" type="search" id="tipoDocumentoDescrizione" placeholder="Tipo Documento"></b-form-input>
          </b-col>
        </b-row>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col sm="">
            <b-form-input v-model="filtro.cognomeRagioneSoggetto" type="search" id="ragioneSoggetto" placeholder="Ragione Sociale"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">Cerca</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">Resetta Filtro</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>Totale Record: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(tipoDocumentoDescrizione)="{ item }">
            <router-link class="sa-edit-link" :to="'/fatturazione/view/' + item.id">{{ item.tipoDocumentoDescrizione }}</router-link>
          </template>
          <template v-slot:cell(cognomeRagioneSoggetto)="{ item }"> {{ item.cognomeRagioneSoggetto }} {{ item.nomeSoggetto }} </template>
          <template #cell(actions)>
            <b-button size="sm" variant="btn btn-purple waves-effect  waves-light width-sm no-text" style="margin-right:3px" @click="onStampa">
              <b-icon icon="printer"></b-icon>
            </b-button>
            <b-button size="sm" variant="btn btn-outline-info no-text" @click="onDownload">
              <b-icon icon="download"></b-icon>
            </b-button>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>Totale Record: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  components: { DatePicker, SaPageLayout },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      filter: null,
      btnRefreshVisible: true,
      btnNewVisible: true,
      linkedit: "/fatturazione",
      pathRest: "/documenti",
      filtro: {},
      filterOn: [],
      fields: [
        {
          label: this.$i18n.t("billing.lblDate"),
          key: "dataDocumento",
          sortable: true,
          thStyle: "width: 7rem",
          formatter: (value) => {
            return moment(new Date(value)).format("DD-MM-YYYY");
          },
        },
        {
          //label: this.$i18n.t("billing.lblDocumentNumber"), <- Correggere su Traduora
          label: "Num.",
          key: "numeroDocumento",
          thStyle: "width: 5rem",
          sortable: true,
        },
        {
          label: this.$i18n.t("billing.lblDocumentType"),
          key: "tipoDocumentoDescrizione",
          sortable: true,
        },
        {
          label: this.$i18n.t("billing.lblCompanyName"),
          key: "cognomeRagioneSoggetto",
          sortable: true,
        },
        {
          //label: this.$i18n.t("billing.lblTotaleDocumento"), <- Correggere su Traduora
          label: "Totale",
          key: "totaleDocumento",
          sortable: true,
          thStyle: "width: 7rem",
          tdClass: "text-right",
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 6rem",
        },
        //{ class: "sa-table-column-action-single", key: "actions", label: "" },
      ],
      items: [],
    };
  },
  created: function() {},
  mounted: function() {
    //let me = this;
    //me.$refs["SaPageList"].show();
    this.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathRest;
      me.isBusy = true;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data;
          //me.$refs["SaPageList"].close();
        })
        .catch(() => {
          me.showmolalloading = false;
        });
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onStampa() {
      console.log("STAMPA RIGA");
    },
    onDownload() {
      console.log("DOWNLOAD RIGA");
    },
  },
};
</script>
