<template>
  <sa-page-layout :showModalLoading="showModalLoading" :linkedit="linkedit" :btnNewVisible="true">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" responsive stacked="lg" striped hover :items="items" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(dataEvento)="{ item }">
            <router-link class="sa-edit-link" :to="'/paziente/gastroenterologia/egds/view/' + item.id + '?idPaziente=' + idPaziente"> {{ formatDate(item.dataEvento) }} </router-link>
          </template>
          <template #cell(actions)>
            <b-button size="sm" variant="btn btn-purple waves-effect  waves-light width-sm no-text" style="margin-right:3px">
              <b-icon icon="printer"></b-icon>
            </b-button>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import UtilityMixin from "../../../../utility/UtilityMixin";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { SaPageLayout, PazienteAnagrafica },
  data() {
    return {
      showModalLoading: false,
      linkedit: null,
      currentPage: 1,
      id: -1,
      perPage: 10,
      filtro: {},
      items: [],
      pathResource: "/gastroegds",
      fields: [
        {
          label: "Data Evento",
          key: "dataEvento",
          sortable: true,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 3rem",
        },
      ],
    };
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Gastroenterologia - EGDS";
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/gastroenterologia/egds/edit/" + me.id + "?idPaziente=" + me.idPaziente;
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idPaziente=" + me.idPaziente;
      console.log(link);
      axios
        .get(link)
        .then((response) => {
          me.items = response.data.data;
          console.log(me.items);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY");
      } else {
        return "---";
      }
    },
  },
};
</script>
