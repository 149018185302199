import { render, staticRenderFns } from "./AgendaDayGriglia.vue?vue&type=template&id=1655e469&scoped=true&"
import script from "./AgendaDayGriglia.vue?vue&type=script&lang=js&"
export * from "./AgendaDayGriglia.vue?vue&type=script&lang=js&"
import style0 from "./AgendaDayGriglia.vue?vue&type=style&index=0&id=1655e469&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1655e469",
  null
  
)

export default component.exports