<template>
  <sa-page-layout ref="pgaView" :pathResource="pathResource" :btnEditVisible="true" :btnAnnullaVisible="true" :saveVisible="false" :linkback="linkback" :linkedit="linkedit" :showmolalloading="showmolalloading">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-body">
      <prima-visita-view-component :idEvento="idEvento"></prima-visita-view-component>
    </template>
  </sa-page-layout>
</template>
<script>
import PrimaVisitaViewComponent from "./PrimaVisitaViewComponent.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  components: { PrimaVisitaViewComponent, PazienteAnagrafica, SaPageLayout },
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  data() {
    return {
      pathResource: "/diabetologiaprimavisita",
      linkback: "/paziente/diabetologiaprimavisita/",
      linkedit: null,
      showmolalloading: false,
      id: "-1",
      profili: [],
      provenienzaOption: [{ value: null, text: null }],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    idEvento() {
      return this.$route.params.id;
    },
    dataEventoPlaceholder() {
      return this.$i18n.t("patient.events.lblEventDate");
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo.menuGruppo) {
          if (this.utente.gruppo.menuGruppo.length > 1) {
            return "view/" + this.idPaziente;
          } else {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "view/" + this.idPaziente;
              } else {
                return "homepaziente";
              }
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    },
  },
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/diabetologiaprimavisita/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    me.linkback = "/paziente/diabetologiaprimavisita?idPaziente=" + me.idPaziente;
  },
};
</script>
