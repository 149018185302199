<template>
  <div>
    <template>
      <h1>{{ this.$i18n.t("therapy.lblResults") }}</h1>
      <b-container
        ><b-row>
          <b-col v-for="(item, index) in data" :key="item.atcGmp + item.codiceProdotto">
            <b-row>
              <b-col>
                <div class="box-medicina">
                  <b-row
                    ><b-col cols="3" style=""
                      ><font-awesome-icon
                        icon="pills"
                        size="4x"
                        style="
                    color: rgb(52, 153, 248);
                    margin: 10px auto;
                    margin-left: 10px;
                  "
                      ></font-awesome-icon
                    ></b-col>
                    <b-col class="text-center" cols="9">
                      <div>
                        {{ item.farmaco }}<br />
                        <b>ATC: </b>{{ item.atcGmp }}<br />
                        <b>{{ returnProdCode() }}: </b>{{ item.codiceProdotto }}
                        <div style="height:10px;"></div>
                      </div>

                      <!-- <div style="height:5px;"></div>
                      <div style="height:25px;text-align:center">
                        <span><b>ATC: </b>{{ item.atcGmp }}</span>
                      </div>
                      <div style="height:5px;"></div>
                      <div style="height:25px;text-align:center">
                        <span><b>Codice: </b>{{ item.codiceProdotto }}</span>
                      </div> -->
                    </b-col></b-row
                  >
                </div>
              </b-col>
              <b-col v-if="index < data.length - 1"> <font-awesome-icon icon="plus" size="4x" class="centrale"></font-awesome-icon></b-col>
            </b-row>
          </b-col> </b-row
      ></b-container>
      <hr />

      <table class="body-wrap">
        <tr>
          <td class="container">
            <div class="content">
              <table class="main" width="100%" cellpadding="0" cellspacing="0">
                <tr>
                  <td class="content-wrap aligncenter">
                    <table width="100%" cellpadding="0" cellspacing="0">
                      <h3>{{ this.$i18n.t("therapy.lblGeneralWarnings") }}</h3>
                      <tr v-if="avvertenzeCheck === 0">
                        <div style="height: 20px"></div>
                        <h4>{{ this.$i18n.t("therapy.lblNoWarnings") }}</h4>
                        <div style="height: 20px"></div>
                        <hr />
                      </tr>
                      <tr v-if="avvertenzeCheck !== 0">
                        <td class="content-block">
                          <table class="invoice" :key="componentKey">
                            <tr>
                              <td></td>
                            </tr>
                            <tr>
                              <td>
                                <table class="invoice-items" cellpadding="0" cellspacing="0" v-for="item in avvertenzeFarmaco" :key="item.product.id + '-avv-farma' + Math.random()">
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                  <td
                                    colspan="2"
                                    style="
                                      border: 0px;
                                      padding-bottom: 5px;
                                      padding-top: 0px;
                                    "
                                  >
                                    <table width="100%" cellpadding="0" cellspacing="0">
                                      <tr>
                                        <td :style="'width: 20px; background-color:' + item.color.htmlCode">
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </td>
                                        <td
                                          style="
                                            padding: 10px;
                                            border-bottom: 0;
                                          "
                                        >
                                          {{ item.product.name }} :
                                          {{ item.description }}
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <hr />
                      <h3>{{ this.$i18n.t("therapy.lblInteractions") }}</h3>
                      <tr v-if="interazioniCheck === 0">
                        <div style="height: 20px"></div>
                        <h4>{{ this.$i18n.t("therapy.lblNoInteractions") }}</h4>
                        <div style="height: 20px"></div>
                        <hr />
                      </tr>
                      <tr v-if="interazioniCheck !== 0">
                        <td class="content-block">
                          <p>
                            {{ interazioni.interactionColor.interactionDescription }}
                          </p>
                          <table class="invoice">
                            <tr v-if="farmaciCheck !== 0">
                              <td>
                                <h4>{{ this.$i18n.t("therapy.lblDrugToDrugInteraction") }}</h4>
                              </td>
                            </tr>
                            <tr v-if="farmaciCheck !== 0">
                              <td>
                                <table class="invoice-items" cellpadding="0" cellspacing="0" v-for="item in farmacoFarmaco" :key="item.product.id + '-farma-farma' + Math.random()">
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                  <td
                                    colspan="2"
                                    style="
                                      border: 0px;
                                      padding-bottom: 5px;
                                      padding-top: 0px;
                                    "
                                  >
                                    <table width="100%" cellpadding="0" cellspacing="0">
                                      <tr>
                                        <td :style="'width: 20px; background-color:' + item.color.htmlCode">
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </td>
                                        <td
                                          style="
                                            padding: 10px;
                                            border-bottom: 0;
                                          "
                                        >
                                          {{ item.affectingProduct.name }}
                                          + {{ item.product.name }} :
                                          {{ item.description }}
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table class="invoice">
                            <tr v-if="dietaCheck !== 0">
                              <td>
                                <h4>{{ this.$i18n.t("therapy.lblDrugToDietInteraction") }}</h4>
                              </td>
                            </tr>
                            <tr v-if="dietaCheck !== 0">
                              <td>
                                <table class="invoice-items" cellpadding="0" cellspacing="0" v-for="item in farmacoDieta" :key="item.product.id + '-farma-dieta' + Math.random()">
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                  <td
                                    colspan="2"
                                    style="
                                      border: 0px;
                                      padding-bottom: 5px;
                                      padding-top: 0px;
                                    "
                                  >
                                    <table width="100%" cellpadding="0" cellspacing="0">
                                      <tr>
                                        <td :style="'width: 20px; background-color:' + item.color.htmlCode">
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </td>
                                        <td
                                          style="
                                            padding: 10px;
                                            border-bottom: 0;
                                          "
                                        >
                                          {{ item.affectingProduct.name }} + {{ item.product.titckName }} :
                                          {{ item.description }}
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table class="invoice">
                            <tr v-if="icdCheck !== 0">
                              <td>
                                <h4>{{ this.$i18n.t("therapy.lblDrugToICDInteraction") }}</h4>
                              </td>
                            </tr>
                            <tr v-if="icdCheck !== 0">
                              <td>
                                <table class="invoice-items" cellpadding="0" cellspacing="0" v-for="item in farmacoICD" :key="item.product.id + '-farma-icd' + Math.random()">
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                  <td
                                    colspan="2"
                                    style="
                                      border: 0px;
                                      padding-bottom: 5px;
                                      padding-top: 0px;
                                    "
                                  >
                                    <table width="100%" cellpadding="0" cellspacing="0">
                                      <tr>
                                        <td :style="'width: 20px; background-color:' + item.color.htmlCode">
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </td>
                                        <td
                                          style="
                                            padding: 10px;
                                            border-bottom: 0;
                                          "
                                        >
                                          {{ item.affectingProduct.name }} + {{ item.product.titckName }} :
                                          {{ item.description }}
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table class="invoice">
                            <tr v-if="pazienteCheck !== 0">
                              <td>
                                <h4>{{ this.$i18n.t("therapy.lblDrugToPatientInteraction") }}</h4>
                              </td>
                            </tr>
                            <tr v-if="pazienteCheck !== 0">
                              <td>
                                <table class="invoice-items" cellpadding="0" cellspacing="0" v-for="item in farmacoPaziente" :key="item.product.id + '-farma-paz' + Math.random()">
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                  <td
                                    colspan="2"
                                    style="
                                      border: 0px;
                                      padding-bottom: 5px;
                                      padding-top: 0px;
                                    "
                                  >
                                    <table width="100%" cellpadding="0" cellspacing="0">
                                      <tr>
                                        <td :style="'width: 20px; background-color:' + item.color.htmlCode">
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </td>
                                        <td
                                          style="
                                            padding: 10px;
                                            border-bottom: 0;
                                          "
                                        >
                                          {{ item.affectingProduct.name }} + {{ item.product.titckName }} :
                                          {{ item.description }}
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table class="invoice">
                            <tr v-if="sintomiCheck !== 0">
                              <td>
                                <h4>{{ this.$i18n.t("therapy.lblDrugToSymptomsInteraction") }}</h4>
                              </td>
                            </tr>
                            <tr v-if="sintomiCheck !== 0">
                              <td>
                                <table class="invoice-items" cellpadding="0" cellspacing="0" v-for="item in farmacoSintomi" :key="item.product.id + '-farma-sintomi' + Math.random()">
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                  <td
                                    colspan="2"
                                    style="
                                      border: 0px;
                                      padding-bottom: 5px;
                                      padding-top: 0px;
                                    "
                                  >
                                    <table width="100%" cellpadding="0" cellspacing="0">
                                      <tr>
                                        <td :style="'width: 20px; background-color:' + item.color.htmlCode">
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </td>
                                        <td
                                          style="
                                            padding: 10px;
                                            border-bottom: 0;
                                          "
                                        >
                                          {{ item.affectingProduct.name }} + {{ item.product.titckName }} :
                                          {{ item.description }}
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <hr />
                    </table>
                  </td>
                </tr>
              </table>
            </div>
          </td>
        </tr>
      </table>
    </template>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["interazioneFarmaci", "modal"],
  data() {
    return {
      risposta: null,
      componentKey: 0,
      data: [],
      interazioni: {},
      avvertenzeFarmaco: [],
      farmacoFarmaco: [],
      farmacoNutrizione: [],
      farmacoICD: [],
      farmacoPaziente: [],
      farmacoSintomi: [],
      interazioniCheck: 0,
      avvertenzeCheck: 0,
      farmaciCheck: 0,
      dietaCheck: 0,
      icdCheck: 0,
      pazienteCheck: 0,
      sintomiCheck: 0,
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/pazientiterapia/attiva?idPaziente=" + me.idPaziente;

      axios
        .get(link)
        .then((res) => {
          me.data = res.data.data;
          me.data.push(me.interazioneFarmaci);
          me.checkInterazioni();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkInterazioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/interazionefarmaci";
      let json = [];
      me.data.forEach((element) => {
        // console.log(element);
        let obj = { farmacoAtc: element.atcGmp };
        json.push(obj);
      });
      json.push({ farmacoAtc: me.interazioneFarmaci.atcGmp });
      axios.post(link, json).then((res) => {
        me.interazioni = res.data.data.data;
        // console.log(me.interazioni);
        var event = new CustomEvent("takeInterazioni", { detail: me.interazioni });
        let check = me.interazioni.productInteraction.length;
        if (check !== 0) {
          var checkInteraction = new CustomEvent("interazioniTrue", { detail: true });
          document.dispatchEvent(checkInteraction);
        }
        me.avvertenzeFarmaco = res.data.data.data.productWarning;
        me.farmacoFarmaco = res.data.data.data.productInteraction;
        me.farmacoDieta = res.data.data.data.productNutritionInteraction;
        me.farmacoICD = res.data.data.data.productICDInteraction;
        me.farmacoPaziente = res.data.data.data.productPatientCharacteristicInteraction;
        me.farmacoSintomi = res.data.data.data.productSymptomInteraction;
        me.avvertenzeCheck = me.avvertenzeFarmaco.length;
        me.farmaciCheck = me.farmacoFarmaco.length;
        me.dietaCheck = me.farmacoDieta.length;
        me.icdCheck = me.farmacoICD.length;
        me.pazienteCheck = me.farmacoPaziente.length;
        me.sintomiCheck = me.farmacoSintomi.length;
        me.interazioniCheck = me.farmaciCheck + me.dietaCheck + me.icdCheck + me.pazienteCheck + me.sintomiCheck + me.avvertenzeCheck;
        me.componentKey += 1;
        document.dispatchEvent(event);
      });
    },
    returnProdCode() {
      return this.$i18n.t("therapy.lblProductCode");
    },
  },
  mounted: function() {
    let me = this;
    me.loadData();
  },
};
</script>

<style scoped>
.invoice {
  border-color: #c2c2c200;
}
.box-medicina {
  min-height: 50px !important;
  width: 300px !important;
  border-width: 2px !important;
  border-color: rgb(52, 153, 248) !important;
  border-style: solid groove !important;
  border-radius: 16px !important;
  display: flex;
  flex-direction: column;
}
.centrale {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: rgb(52, 153, 248);
}
</style>
