<template>
  <div>
    <b-form @submit.prevent="onSubmit">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col sm="4">
          <b-form-input v-model="filtro.titolo" type="search" id="filterInput" :placeholder="this.$i18n.t('agendas.lblTitle')"></b-form-input>
        </b-col>
        <b-col sm="4">
          <b-form-input v-model="filtro.descrizionePrestazione" type="search" id="testo" :placeholder="this.$i18n.t('patient.agenda.lblDescriptionPerformance')"></b-form-input>
        </b-col>
        <b-col cols="4">
          <date-picker class="datepicker" format="YYYY-MM-DD" value-type="format" :clearable="false" v-model="setDataRiferimento"></date-picker>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="sa-padding-right text-right">
          <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
          <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
        </b-col>
      </b-row>
    </b-form>

    <b-row style="margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 1rem">
      <b-col cols="6">
        <p style="position: absolute">{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
      </b-col>
      <b-col cols="6">
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
      </b-col>
    </b-row>
    <hr />
    <template>
      <div class="b-table-sticky-header">
        <b-table
          sticky-header
          striped
          stacked="lg"
          hover
          sort-icon-left
          head-variant="light"
          class="sa-b-table"
          responsive
          ref="tableAppuntamenti"
          :per-page="perPage"
          :current-page="currentPage"
          :items="appuntamenti"
          :fields="fieldsAppuntamenti"
          :filter="filtro"
        ></b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
      <sa-modal-loading ref="sa-modal-loading" />
    </template>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { SaModalLoading } from "../../../components/modal";

export default {
  components: {
    SaModalLoading,
    DatePicker,
  },
  props: {
    periodo: String,
    idAgenda: String,
  },
  data() {
    return {
      pathRestAppuntamenti: "/agendaappuntamenti",
      appuntamenti: [],
      dataRiferimento: "",
      perPage: 5,
      currentPage: 1,
      filtro: { descrizionePrestazione: "", titolo: "" },
      fieldsAppuntamenti: [
        {
          label: this.$i18n.t("patient.agenda.lblDateReference"),
          key: "dataRiferimento",
          sortable: true,
          formatter: (value) => {
            return value ? moment(new Date(value)).format("DD-MM-YYYY") : "";
          },
        },
        {
          label: this.$i18n.t("patient.agenda.lblStartTime"),
          key: "dataOraInizio",
          formatter: (value) => {
            return value ? moment(new Date(value)).format("HH:mm") : "";
          },
        },
        {
          label: this.$i18n.t("patient.agenda.lblEndTime"),
          key: "dataOraFine",
          formatter: (value) => {
            return value ? moment(new Date(value)).format("HH:mm") : "";
          },
        },
        {
          label: this.$i18n.t("agenda.appointments.lblAppointmentIdentifier"),
          key: "identificativoAppuntamento",
          sortable: true,
        },
        {
          label: this.$i18n.t("agendas.lblTitle"),
          key: "titolo",
          sortable: true,
        },
        {
          label: this.$i18n.t("patient.agenda.lblDescriptionPerformance"),
          key: "descrizionePrestazione",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    rows() {
      return this.appuntamenti.length;
    },
    setDataRiferimento: {
      get: function() {
        if (this.dataRiferimento === "") {
          return moment(Date()).format("YYYY-MM-DD");
        } else return this.dataRiferimento;
      },
      set: function(value) {
        this.dataRiferimento = value;

        this.loadData();
      },
    },
  },
  mounted: function() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathRestAppuntamenti;
      me.$refs["sa-modal-loading"].show();
      axios
        .get(link, {
          params: {
            idAgenda: me.idAgenda,
            dataRiferimento: me.setDataRiferimento,
          },
        })
        .then((response) => {
          me.appuntamenti = response.data.data;

          me.$refs["sa-modal-loading"].close();
        })
        .catch(function(error) {
          me.$refs["sa-modal-loading"].close();
          me.$bvToast.toast(error.response.data.messaggio, {
            title: "Agenda",
            variant: "danger",
            solid: true,
          });
        });
    },
    onSubmit() {
      let me = this;
      me.appuntamenti = [];
      me.appuntamenti = me.appuntamenti.filter(
        (element) => element.titolo.toUpperCase().includes(me.filtro.titolo.toUpperCase()) && element.descrizionePrestazione.toUpperCase().includes(me.filtro.descrizionePrestazione.toUpperCase())
      );
    },
    onReset() {
      let me = this;
      me.appuntamenti = [];
      me.loadData();
    },
  },
};
</script>
<style></style>
