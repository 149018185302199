import Gastro from "../";

const routes = [
  { name: "AnamnesiList", path: "/paziente/gastroenterologia/anamnesipatologica/", component: Gastro.GastroAnamnesiPatologicaList, meta: { title: "Anamnesi Patologica Gastroenterologia" } },
  { name: "AnamnesiView", path: "/paziente/gastroenterologia/anamnesipatologica/view/:id", component: Gastro.GastroAnamnesiPatologicaView, meta: { title: "Anamnesi Patologica Gastroenterologia" } },
  { name: "AnamnesiEdit", path: "/paziente/gastroenterologia/anamnesipatologica/edit/:id", component: Gastro.GastroAnamnesiPatologicaEdit, meta: { title: "Anamnesi Patologica Gastroenterologia" } },

  { name: "AnamnesiList", path: "/paziente/gastroenterologia/anamnesifisiologica/", component: Gastro.GastroAnamnesiFisiologicaList, meta: { title: "Anamnesi Fisiologica Gastroenterologia" } },
  { name: "AnamnesiView", path: "/paziente/gastroenterologia/anamnesifisiologica/view/:id", component: Gastro.GastroAnamnesiFisiologicaView, meta: { title: "Anamnesi Fisiologica Gastroenterologia" } },
  { name: "AnamnesiEdit", path: "/paziente/gastroenterologia/anamnesifisiologica/edit/:id", component: Gastro.GastroAnamnesiFisiologicaEdit, meta: { title: "Anamnesi Fisiologica Gastroenterologia" } },

  { name: "AnamnesiList", path: "/paziente/gastroenterologia/anamnesifamiliare/", component: Gastro.GastroAnamnesiFamiliareList, meta: { title: "Anamnesi Familiare Gastroenterologia" } },
  { name: "AnamnesiView", path: "/paziente/gastroenterologia/anamnesifamiliare/view/:id", component: Gastro.GastroAnamnesiFamiliareView, meta: { title: "Anamnesi Familiare Gastroenterologia" } },
  { name: "AnamnesiEdit", path: "/paziente/gastroenterologia/anamnesifamiliare/edit/:id", component: Gastro.GastroAnamnesiFamiliareEdit, meta: { title: "Anamnesi Familiare Gastroenterologia" } },

  { name: "AnamnesiList", path: "/paziente/gastroenterologia/anorettocolonscopia/", component: Gastro.GastroAnoRettoColonscopiaList, meta: { title: "Ano-Retto Colonscopia Gastroenterologia" } },
  { name: "AnamnesiView", path: "/paziente/gastroenterologia/anorettocolonscopia/view/:id", component: Gastro.GastroAnoRettoColonscopiaView, meta: { title: "Ano-Retto Colonscopia Gastroenterologia" } },
  { name: "AnamnesiEdit", path: "/paziente/gastroenterologia/anorettocolonscopia/edit/:id", component: Gastro.GastroAnoRettoColonscopiaEdit, meta: { title: "Ano-Retto Colonscopia Gastroenterologia" } },

  { name: "EgdsEdit", path: "/paziente/gastroenterologia/egds/edit/:id", component: Gastro.EgdsEdit, meta: { title: "Visita Egds" } },
  { name: "EgdsView", path: "/paziente/gastroenterologia/egds/view/:id", component: Gastro.EgdsView, meta: { title: "Visita Egds " } },
  { name: "EgdsList", path: "/paziente/gastroenterologia/egds/:id", component: Gastro.EgdsList, meta: { title: "Visita Egds " } },

  { name: "VisitaGastroenterologicaEdit", path: "/paziente/gastroenterologia/visitagastroenterologica/edit/:id", component: Gastro.VisitaGastroenterologicaEdit, meta: { title: "Visita Gastroenterologica " } },
];
export default routes;
