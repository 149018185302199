<template>
    <div class="container">
        <div class="sa-chat">
            <div class="sa-chat-header">
                <span style="padding-left: 1rem;">{{ this.$i18n.t("patient.chat.lblYouAreTalkingTo") }}: </span>
                <span>
                    <strong>{{ chatUser.lastname }} {{ chatUser.firstname }}</strong>
                </span>
                <a href="#" class="sa-icon-sidebar-chat" @click="onCollaps" onclick="return false" v-b-toggle.sidebar-right><i id="iconSidebar" :collapsed="collapsed" class="fas fa-ellipsis-v" aria-hidden="true"></i></a>
                <a href="#" class="sa-icon-close-chat" onclick="return false" @click="onChatHiddenClick"><i class="fas fa-times"></i></a>
            </div>
            <div id="chatScroll" class="sa-chat-body">
                <b-sidebar id="sidebar-right" ref="side-bar" title="Messaggia con: " class="sa-sidebar-chat" right width="500px" no-header-close shadow @change="toggleBodyScrollbar">
                    <div class="px-3 py-2">
                        <div class="sa-chat-search-box">
                            <b-input-group size="sm">
                                <b-form-input id="filter-input" v-model="search" type="text" :value="value" placeholder="Type to Search"></b-form-input>
                                <b-input-group-append>
                                    <b-button :disabled="!search" @click="search = ''">Clear</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <ul class="users" v-for="user in filteredItems" :key="user.user" @click="selectUser(user)">
                            <li class="person">
                                <div class="user">
                                    <img src="https://www.bootdey.com/img/Content/avatar/avatar5.png" />
                                    <span class="status online"></span>
                                </div>
                                <p class="name-time">
                                    <span class="name">{{ user.lastname }} {{ user.firstname }}</span>
                                    <span class="time">{{ user.tempo }}</span>
                                </p>
                            </li>
                        </ul>
                    </div>
                </b-sidebar>
                <div :class="getClassMessage(message)" v-for="message in jsonData" :key="message.id">
                    <div>
                        <b-row>
                            <span style="font-size: 13px">{{ formatDateTime(message.dataOra) }}</span>
                        </b-row>
                        <span>
                            {{ message.contentMessage }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="sa-chat-footer">
                <b-row>
                    <b-input-group size="sm" class="mb-2">
                        <b-form-textarea id="textarea-default" placeholder="Scrivi qui il messaggio..." no-resize size="sm" v-model="contentMessage" v-on:keyup.enter="onInviaMessaggio"></b-form-textarea>
                        <b-input-group-append>
                            <b-button @click="onInviaMessaggio" :disabled="chatUser.username === ''">
                                <i class="far fa-paper-plane"></i>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-row>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin.js";

export default {
    props: {
        utente: {
            type: Object,
            default: function() {
                return { username: null };
            },
        },
    },
    mixins: [UtilityMixin],
    components: {},
    data() {
        return {
            collapsed: true,
            connection: null,
            componentKey: 0,
            disableButtonInvioMessaggio: true,
            value: "",
            pathResource: "/chatmessage/messaggiutente",
            pathResourceInvioMess: "/chatmessage",
            pathResourceChatUtenti: "/chatusers",
            iconCollapsed: "fas fa-ellipsis-h",
            search: null,
            users: [],
            chatUser: { lastname: "", firstname: "", username: "" },
            jsonData: [],
            objectMessaggio: { contentMessage: "", dataOra: 0, fromMessage: "", toMessage: "", readMessage: false },
            contentMessage: "",
            // linkWs: "wss://wshyperhealth.gesan.it/gesan/chat/",
            linkWs: "ws://wshyperhealth.gesan.it/gesan/chat/",
        };
    },
    mounted() {
        let me = this;
        //me.loadData();
        me.loadUtenti();
    },
    watch: {
        utente: function() {
            console.log("1");
            let me = this;
            if (me.utente !== null) {
                console.log("2");
                if (me.utente && me.utente.username) {
                    console.log("OK");
                    this.connection = new WebSocket(me.linkWs + me.utente.username);

                    this.connection.onmessage = function() {
                        me.loadData();
                    };

                    this.connection.onopen = function() {
                        console.log("Successfully connected to the echo websocket server...");
                    };
                }
            } else {
                console.log("3");
            }
        },
    },
    created: function() {},
    updated() {
        let me = this;
        me.scrollToEnd();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            axios
                .get(link, {
                    params: {
                        usernameTo: me.chatUser.username,
                    },
                })
                .then((response) => {
                    me.jsonData = response.data.data;
                })
                .catch(() => {
                    //console.log(error);
                });
        },
        loadUtenti() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceChatUtenti;
            axios
                .get(link)
                .then((response) => {
                    me.users = response.data.data;
                })
                .catch(() => {
                    //console.log(error);
                });
        },
        getClassMessage(message) {
            let me = this;
            let returnValue = "sa-chat-utente";
            if (me.utente !== null) {
                if (message.toMessage === me.utente.username) {
                    returnValue = "sa-chat-destinatario";
                }
            }
            return returnValue;
        },
        onChatHiddenClick() {
            let me = this;
            me.$emit("update");
        },
        scrollToEnd() {
            var div = document.getElementById("chatScroll");
            div.scrollTop = div.scrollHeight - div.clientHeight;
        },
        onCollaps() {
            this.collapsed = !this.collapsed;
            // const icon = document.getElementsByClassName("fas fa-ellipsis-v").value();
            // console.log(icon);
            // icon.className.replace("fas fa-ellipsis-h");

            // var icon = document.getElementById("iconSidebar");

            // icon.addEventListener(
            //   "click",
            //   function() {
            //     this.classList.toggle("fa-ellipsis-v");
            //     this.classList.toggle("fa-ellipsis-h");
            //   },
            //   false
            // );
        },
        toggleBodyScrollbar(visible) {
            const body = document.getElementsByClassName("sa-chat-body")[0];

            if (visible) {
                body.classList.add("overflow-hidden");
            } else body.classList.remove("overflow-hidden");
        },
        toggleStatus(status) {
            const stato = document.getElementsByClassName("status")[0];
            //  Da inserire Nel load data degli utenti
            if (status) {
                stato.classname.replace("status online");
            } else stato.className.replace("status offline");
        },
        onInviaMessaggio() {
            console.log("Invio");
            let me = this;

            // JSON MESSAGGIO
            me.objectMessaggio.contentMessage = me.contentMessage;
            me.objectMessaggio.fromMessage = me.utente.username;
            me.objectMessaggio.toMessage = me.chatUser.username;
            me.objectMessaggio.readMessage = false;
            me.objectMessaggio.dataOra = new Date();
            console.log(me.objectMessaggio);
            let jsonData = JSON.stringify(me.objectMessaggio);
            this.connection.send(jsonData);
            me.contentMessage = "";
        },
        selectUser(item) {
            let me = this;
            me.chatUser.lastname = item.lastname;
            me.chatUser.firstname = item.firstname;
            me.chatUser.username = item.username;

            const body = document.getElementsByClassName("sa-chat-body")[0];
            body.classList.remove("overflow-hidden");
            //const sidebar = document.getElementById("sidebar-right");
            //sidebar.setAttribute("style", "display:none; width: 100% !important;");
            //sidebar.classList.toggle("");
            this.$refs["side-bar"].hide();
            me.loadData();

            const selectedItem = document.querySelectorAll(".person");
            selectedItem.forEach((li) => {
                li.addEventListener("click", () => {
                    li.classList.toggle("alt-background");
                });
            });
        },
    },
    computed: {
        filteredItems() {
            if (this.search) {
                return this.users.filter((user) => {
                    return this.search
                        .toLowerCase()
                        .split(" ")
                        .every((value) => user.lastname.toLowerCase().includes(value));
                });
            } else {
                return this.users;
            }
        },
    },
};
</script>
<style>
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.users {
    padding: 0;
}

.users .person {
    position: relative;
    width: 100%;
    padding: 10px 1rem;
    cursor: pointer;
    border-bottom: 1px solid #f0f4f8;
}

.users .person:hover {
    background-color: #ffffff;
}

.users .person.active-user {
    background-color: #ffffff;
}
.users .person .user {
    display: inline-block;
    position: relative;
    margin-right: 10px;
}

.users .person .user img {
    width: 48px;
    height: 48px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
}

.users .person .user .status {
    width: 10px;
    height: 10px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    background: #e6ecf3;
    position: absolute;
    top: 0;
    right: 0;
}

.users .person .user .status.online {
    background: #9ec94a;
}

.users .person .user .status.offline {
    background: #fd7274;
}

.users .person p.name-time {
    font-weight: 600;
    font-size: 0.85rem;
    display: inline-block;
}

.users .person p.name-time .time {
    font-weight: 400;
    font-size: 0.7rem;
    text-align: right;
    color: #8796af;
    padding-left: 0.5rem;
}

@media (max-width: 767px) {
    .users .person .user img {
        width: 30px;
        height: 30px;
    }
    .users .person p.name-time {
        display: none;
    }
    .users .person p.name-time .time {
        display: none;
    }
}
.stop-scrolling {
    height: 100%;
    overflow: hidden;
}
.alt-background {
    background-color: #ffffff;
}
</style>
