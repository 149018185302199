<template>
    <div :class="classDisponibile">
        <div>
            <a :id="id" href="#" onclick="return false;">
                <span>{{ titolo }}</span>
            </a>
        </div>
        <!-- Popover Disponibilità -->
        <b-popover variant=" sa-calendar-popover" :target="id" triggers="focus">
            <template v-slot:title>
                <div style="display: flex">
                    <div style="display: flex; flex: 1; margin-top: 0.4rem">
                        {{ titolo }}
                    </div>
                    <div style="display: flex; width: 4rem">
                        <div class="sa-icon-btn" :hidden="!scaduto">
                            <b-icon icon="pencil" variant="outline-primary" @click="onCrea" />
                        </div>
                        <div class="sa-icon-btn" :hidden="!identificativo">
                            <b-icon icon="arrows-move" variant="outline-primary" @click="onModifica" />
                        </div>
                        <div class="sa-icon-btn" :hidden="!identificativo">
                            <b-icon icon="trash" variant="outline-primary" @click="onDelete($event.target)" />
                        </div>
                    </div>
                </div>
            </template>
            <p>
                <b-icon icon="clock" variant="outline-primary" /><span class="sa-agenda-popover-span">{{ slotDisponibilita.dalleOre }}</span>
            </p>
            <p>
                <b-icon icon="stopwatch" variant="outline-primary" /><span class="sa-agenda-popover-span">{{ slotDisponibilita.alleOre }}</span>
            </p>
            <p v-if="identificativo">
                <span class="sa-agenda-popover-span"
                    >{{ this.$i18n.t("patient.agenda.lblIdentifier") }}: <a class="sa-edit-link" :href="getLinkRange(identificativo)">{{ identificativo }}</a>
                </span>
            </p>
            <p v-if="identificativo">
                <span class="sa-agenda-popover-span">{{ this.$i18n.t("patient.agenda.lblPerformanceCode") }}: {{ codicePrestazioneApp }}</span>
            </p>
            <p v-if="identificativo">
                <span class="sa-agenda-popover-span">{{ this.$i18n.t("agendas.lblDescription") }}: {{ descrizionePrestazioneApp }}</span>
            </p>
            <p v-if="identificativo">
                <span class="sa-agenda-popover-span">{{ this.$i18n.t("patient.diet.lblNote") }}: {{ noteAppuntamento }}</span>
            </p>
            <p v-if="identificativo">
                <span class="sa-agenda-popover-span">{{ this.$i18n.t("patient.agenda.lblCost") }}: {{ costoPrestazione + " €" }}</span>
            </p>
        </b-popover>
        <!-- Modal Prestazione -->
        <b-modal ref="modal-prest" v-model="modalShow" @show="onShowModal" no-close-on-backdrop no-close-on-select :title="this.$i18n.t('agenda.DailyAvailability')" hide-footer>
            <div class="modal-body">
                <b-row>
                    <label for="titolo">{{ this.$i18n.t("agendas.lblTitle") }} </label>
                    <input type="text" name="titolo" class="form-control" v-model="slotDisponibilita.titolo" />
                </b-row>

                <b-row>
                    <label for="identificativoAppuntamento"> {{ this.$i18n.t("patients.lblFiscalCode") }}</label>
                    <input type="text" name="identificativoAppuntamento" class="form-control" v-model="identificativo" />
                </b-row>

                <b-row>
                    <label for="codicePrestazione">{{ this.$i18n.t("patient.agenda.lblPerformanceCode") }}</label>
                    <input name="codicePrestazione" class="form-control" v-model="codicePrestazioneApp" :disabled="codicePrestazione !== ''" />
                </b-row>

                <b-row>
                    <label for="descrizionePrestazione">{{ this.$i18n.t("patient.agenda.lblDescriptionPerformance") }}</label>
                    <input type="text" name="descrizionePrestazione" class="form-control" v-model="descrizionePrestazione" :disabled="descrizionePrestazione !== ''" />
                </b-row>

                <b-row>
                    <label for="costo">{{ this.$i18n.t("patient.agenda.lblCost") }}</label>
                    <input type="text" name="costo" class="form-control" v-model="costoPrestazione" :disabled="costoPrestazione !== ''" />
                </b-row>

                <b-row :hidden="modifica">
                    <label for="dataOraInizio">{{ this.$i18n.t("patient.agenda.lblDateReference") }}</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="dataAppuntamento" type="timestamp" :disabled="!modifica"></date-picker>
                </b-row>

                <b-row :hidden="modifica">
                    <b-col cols="6">
                        <label for="dataInizio">{{ this.$i18n.t("patient.agenda.lblStartTime") }}</label>
                        <date-picker format="HH:mm" value-type="timestamp" v-model="oraInizio" type="time" placeholder="HH:mm" :disabled="!modifica"></date-picker>
                    </b-col>
                    <b-col cols="6">
                        <label for="indirizzo">{{ this.$i18n.t("patient.agenda.lblEndTime") }}</label>
                        <date-picker format="HH:mm" value-type="timestamp" v-model="oraFine" type="time" placeholder="HH:mm" :disabled="!modifica"></date-picker>
                    </b-col>
                </b-row>

                <b-row>
                    <label for="note">{{ this.$i18n.t("patient.diet.lblNote") }}</label>
                    <b-form-textarea class="form-control" name="note" v-model="noteAppuntamento" rows="3" no-resize></b-form-textarea>
                </b-row>
            </div>
            <hr />
            <b-row>
                <div class="sa-padding-right text-right col">
                    <b-button type="button" variant="secondary" @click="onCancel">{{ this.$i18n.t("global.lblBtnCancel") }}</b-button>
                    <b-button type="button" variant="primary" @click="onSave">{{ this.$i18n.t("global.lblBtnSave") }}</b-button>
                </div>
            </b-row>
        </b-modal>
        <!-- MODAL SPOSTA APPUNTAMENTO -->
        <b-modal ref="modal-mod-appuntamento" class="modal-modifica-data" v-model="modalChangeApp" size="xl" no-close-on-backdrop no-close-on-select :title="this.$i18n.t('agenda.lblMoveAppointment')" hide-footer>
            <agenda-modal-sposta-appuntamento :slotDisponibilita="slotDisponibilita" :idAgenda="idAgenda" :dataAppuntamento="dataAppuntamento" @update="updateDataRiferimento" @updatedataRiferimento="updateDataRiferimento"></agenda-modal-sposta-appuntamento>
            <hr />
            <b-row>
                <div class="sa-padding-right text-right col">
                    <b-button type="button" variant="secondary" @click="modalChangeApp = false">{{ this.$i18n.t("global.lblBtnCancel") }}</b-button>
                    <b-button type="button" variant="primary" @click="onSave">{{ this.$i18n.t("patient.agenda.lblMove") }}</b-button>
                </div>
            </b-row>
        </b-modal>
        <sa-modal-loading ref="sa-modal-loading" />
    </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../utility/UtilityMixin.js";
import axios from "axios";
import { SaModalLoading } from "../../../components/modal/index";
import moment from "moment";
import AgendaModalSpostaAppuntamento from "./AgendaModalSpostaAppuntamento.vue";

export default {
    mixins: [UtilityMixin],
    components: {
        DatePicker,
        SaModalLoading,
        AgendaModalSpostaAppuntamento,
    },
    data() {
        return {
            modalShow: false,
            modalChangeApp: false,
            data: {},
            objectAppuntamento: { id: "", idAgenda: "", codicePrestazione: "", descrizionePrestazione: "", dataOraInizio: "", dataOraFine: "", identificativoAppuntamento: "", costo: "", note: "" },
            arrayAppuntamento: [],
            identificativoAppuntamento: "",
            pathRestPrestazioni: "/agendaprestazioni",
            pathRestAppuntamenti: "/agendaappuntamenti",
            linkRange: "/paziente/rangeconfiguration?idPaziente=",
            modifica: false,
            scaduto: false,
            dataApp: null,
            dataRiferimentoModificata: this.slotDisponibilita.dataRiferimento,
            nuovoAppuntamento: null,
        };
    },
    props: {
        index: String,
        slotDisponibilita: Object,
        disponibilita: Array,
        codicePrestazione: String,
        descrizionePrestazione: String,
        costo: Number,
        idAgenda: String,
    },
    computed: {
        classDisponibile() {
            let me = this;
            if (me.slotDisponibilita.appuntamento === null) {
                return "sa-calendar-day-slot-prenotazione disponibile";
            } else {
                return "sa-calendar-day-slot-prenotazione nodisponibile";
            }
        },
        dataAppuntamento: {
            get: function() {
                return UtilityMixin.methods.parseFormatDateTime(this.slotDisponibilita.dataRiferimento + "" + this.slotDisponibilita.dalleOre, "YYYY-MM-DD").getTime();
            },
            set: function(value) {
                const data = new Date(value);
                return (this.slotDisponibilita.dataRiferimento = moment(data).format("YYYY-MM-DD"));
            },
        },
        oraInizio: {
            get: function() {
                let me = this;
                return UtilityMixin.methods.parseFormatDateTime(me.slotDisponibilita.dataRiferimento + "" + me.slotDisponibilita.dalleOre, "YYYY-MM-DD HH:mm").getTime();
            },
            set: function(value) {
                let me = this;
                const data = new Date(value);
                me.slotDisponibilita.dalleOre = moment(data).format("HH:mm");
            },
        },
        oraFine: {
            get: function() {
                let me = this;
                return UtilityMixin.methods.parseFormatDateTime(me.slotDisponibilita.dataRiferimento + "" + me.slotDisponibilita.alleOre, "YYYY-MM-DD HH:mm").getTime();
            },
            set: function(value) {
                let me = this;
                const data = new Date(value);
                me.slotDisponibilita.alleOre = moment(data).format("HH:mm");
            },
        },
        id() {
            let me = this;
            return "popover-button_" + me.index;
        },
        titolo() {
            let me = this;
            if (me.slotDisponibilita.titolo === "") {
                return "Disponibile";
            } else {
                return me.slotDisponibilita.titolo;
            }
        },
        descrizionePrestazioneApp: {
            get: function() {
                let me = this;
                if (me.slotDisponibilita.appuntamento === null) {
                    return "";
                } else {
                    return me.slotDisponibilita.appuntamento.descrizionePrestazione;
                }
            },
            set: function(value) {
                let me = this;
                if (me.slotDisponibilita.appuntamento === null) {
                    me.slotDisponibilita.descrizionePrestazione = value;
                } else {
                    me.slotDisponibilita.appuntamento.descrizionePrestazione = value;
                }
            },
        },
        identificativo: {
            get() {
                let me = this;
                if (me.slotDisponibilita.appuntamento === null) {
                    return "";
                } else {
                    return me.slotDisponibilita.appuntamento.identificativoAppuntamento;
                }
            },
            set(value) {
                let me = this;
                if (me.slotDisponibilita.appuntamento === null) {
                    me.slotDisponibilita.identificativoAppuntamento = value;
                } else {
                    me.slotDisponibilita.appuntamento.identificativoAppuntamento = value;
                }
            },
        },
        costoPrestazione() {
            let me = this;
            if (me.slotDisponibilita.appuntamento === null) {
                return me.costo;
            } else {
                return me.slotDisponibilita.appuntamento.costo;
            }
        },
        noteAppuntamento: {
            get() {
                let me = this;
                if (me.slotDisponibilita.appuntamento === null) {
                    return me.objectAppuntamento.note;
                } else {
                    return me.slotDisponibilita.appuntamento.note;
                }
            },
            set(value) {
                let me = this;
                if (me.slotDisponibilita.appuntamento === null) {
                    me.objectAppuntamento.note = value;
                } else {
                    me.slotDisponibilita.appuntamento.note = value;
                }
            },
        },
        codicePrestazioneApp() {
            let me = this;
            if (me.slotDisponibilita.appuntamento === null || me.slotDisponibilita.appuntamento.codicePrestazione === "") {
                return me.codicePrestazione;
            } else {
                return me.slotDisponibilita.appuntamento.codicePrestazione;
            }
        },
    },
    mounted: function() {
        let me = this;
        me.onScaduto();
    },
    methods: {
        getLinkRange(value) {
            let href = "#/paziente/teleconsulti?idPaziente=" + value;

            return href;
        },
        onCancel() {
            let me = this;
            me.modalShow = false;
            me.slotDisponibilita.titolo = "";
            me.identificativo = "";
            me.noteAppuntamento = "";
        },
        onCrea() {
            let me = this;
            me.modalShow = true;
            me.modifica = false;
        },
        onModifica() {
            let me = this;
            me.modalChangeApp = true;
            me.modifica = true;
        },
        onShowModal() {},
        onSave() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRestAppuntamenti;

            /** JSON APPUNTAMENTO */
            me.objectAppuntamento.idAgenda = me.idAgenda;
            me.objectAppuntamento.titolo = me.titolo;
            me.objectAppuntamento.codicePrestazione = me.codicePrestazione;
            me.objectAppuntamento.descrizionePrestazione = me.descrizionePrestazione;
            me.objectAppuntamento.dataOraInizio = me.oraInizio;
            me.objectAppuntamento.dataOraFine = me.oraFine;
            me.objectAppuntamento.dataRiferimento = me.slotDisponibilita.dataRiferimento;
            me.objectAppuntamento.identificativoAppuntamento = me.slotDisponibilita.identificativoAppuntamento;
            me.objectAppuntamento.costo = me.costo;
            me.objectAppuntamento.note = me.noteAppuntamento;
            let jsonData = JSON.stringify(me.objectAppuntamento);

            if (me.slotDisponibilita.appuntamento === null) {
                me.objectAppuntamento.id = "-1";
                axios
                    .post(link, jsonData)
                    .then((response) => {
                        me.data = response.data.data;
                        me.$refs["sa-modal-loading"].close();
                        me.$emit("update");
                        me.modalShow = false;
                        me.$bvToast.toast([this.$i18n.t("agenda.lblAppointmentCreated")], {
                            title: [this.$i18n.t("agenda.lblAppointmentDiary")],
                            variant: "info",
                            solid: true,
                            noCloseButton: true,
                            autoHideDelay: 2000,
                        });
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal.msgBoxOk(error.response.data.messaggio, {
                            title: [this.$i18n.t("agenda.lblAttenction")],
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        });
                    })
                    .then((value) => {
                        this.boxTwo = value;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                // me.objectAppuntamento.idAgenda = me.idAgenda;
                // me.objectAppuntamento.titolo = me.titolo;
                // me.objectAppuntamento.codicePrestazione = me.codicePrestazione;
                // me.objectAppuntamento.descrizionePrestazione = me.descrizionePrestazione;
                // me.objectAppuntamento.dataOraInizio = me.oraInizio;
                // me.objectAppuntamento.dataOraFine = me.oraFine;
                // me.objectAppuntamento.dataRiferimento = me.dataRiferimentoModificata;
                // me.objectAppuntamento.identificativoAppuntamento = me.slotDisponibilita.appuntamento.identificativoAppuntamento;
                // me.objectAppuntamento.costo = me.costo;
                // me.objectAppuntamento.note = me.noteAppuntamento;
                let jsonDataUpdate = JSON.stringify(me.nuovoAppuntamento);
                axios
                    .put(link + "/" + me.slotDisponibilita.appuntamento.id, jsonDataUpdate)
                    .then((response) => {
                        response; //me.data = response.data.data;
                        me.$emit("update");
                        /*me.$refs["sa-modal-loading"].close();
                        me.modalShow = false;
                        me.modalChangeApp = false;
                        
                        me.$bvToast.toast([this.$i18n.t("agenda.lblEditAppointment")], {
                            title: [this.$i18n.t("agenda.lblAppointmentDiary")],
                            variant: "info",
                            solid: true,
                            noCloseButton: true,
                            autoHideDelay: 2000,
                        });*/
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal.msgBoxOk(error.response.data.messaggio, {
                            title: [this.$i18n.t("agenda.lblAttenction")],
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        });
                    })
                    .then((value) => {
                        this.boxTwo = value;
                    })
                    .catch((err) => {
                        console.log(err);
                        me.$refs["sa-modal-loading"].close();
                    });
            }
        },
        onDelete() {
            let me = this;
            this.$bvModal
                .msgBoxConfirm([this.$i18n.t("agenda.lblConfirmAppointmentDelete")] + " " + me.slotDisponibilita.appuntamento.identificativoAppuntamento + " ?")
                .then((item) => {
                    if (item) {
                        let link = process.env.VUE_APP_PATH_API + me.pathRestAppuntamenti;
                        axios.delete(link + "/" + me.slotDisponibilita.appuntamento.id).then(() => {
                            me.$emit("update");
                            me.$bvToast.toast([this.$i18n.t("agenda.lblAppointmentDeleted")], {
                                title: [this.$i18n.t("agenda.lblAppointmentDiary")],
                                variant: "info",
                                solid: true,
                                noCloseButton: true,
                                autoHideDelay: 2000,
                            });
                        });
                    }
                })
                .catch((err) => {
                    this.$bvModal.msgBoxOk(err.response.data.messaggio, {
                        title: [this.$i18n.t("agenda.lblAttenction")],
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                });
        },
        onScaduto() {
            let me = this;
            let dataSlot = new Date();
            let dataFormattata = moment(dataSlot).format("YYYY-MM-DD HH:mm");
            if (me.slotDisponibilita.dataRiferimento + " " + me.slotDisponibilita.dalleOre >= dataFormattata) {
                me.scaduto = true;
            }
        },
        updateData(data) {
            let me = this;
            me.slotDisponibilita = data;
        },
        updateDataRiferimento(data) {
            let me = this;
            console.log(data);
            me.nuovoAppuntamento = data;
            //me.slotDisponibilita.dataRiferimento = data;
            // console.log(data);
            /*me.dataRiferimentoModificata = data;
            console.log("dataRif", me.dataRiferimentoModificata);
            console.log("app", me.slotDisponibilita.appuntamento.dataRiferimento);
            console.log(me.slotDisponibilita);*/
        },
    },
};
</script>
<style scoped>
.sa-calendar-popover .popover-header {
    margin-top: 0px !important;
    background-color: blue;
}

.sa-calendar-day-slot-prenotazione span {
    border-radius: 0.3rem;
    vertical-align: middle;
    display: table-cell;
    padding: 0.3rem;
    white-space: nowrap;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    font-size: 0.8rem;
    padding: 10px;
}

.disponibile span {
    background-color: #abf7b3;
    border-left: 3px solid green;
    color: black;
}

.nodisponibile span {
    background-color: #9bc3ff;
    border-left: 3px solid blue;
    color: black;
}
.sa-icon-btn {
    width: 2rem;
    height: 2rem;
}
.sa-icon-btn svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5rem;
}
.sa-icon-btn svg:hover {
    color: #000;
    cursor: pointer;
}
.sa-agenda-popover-span {
    padding-left: 5px;
}
</style>
