<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" :btnBackVisible="false" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <b-tabs>
        <b-tab active>
          <template #title>
            <span class="text-success"><b-icon icon="check-circle-fill"></b-icon>&nbsp;Dati</span>
          </template>
          <b-row stacked="xl">
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <strong>Visita Neurologica</strong>
              <br /><b-form-textarea v-model="jsonData.visitaNeurologica" rows="8"></b-form-textarea>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <strong>Biotesiometria Malleolo Dx</strong>
              <br /><b-form-select v-model="jsonData.biotesiometriaMalleoloDx" :options="['Normale', 'Ridotta']"></b-form-select>
              <strong>Biotesiometria Malleolo Sx</strong>
              <br /><b-form-select v-model="jsonData.biotesiometriaMalleoloSx" :options="['Normale', 'Ridotta']"></b-form-select>
              <strong>Biotes. Primo Dito Piede Dx</strong>
              <br /><b-form-select v-model="jsonData.biotesiometriaPrimoDitoPiedeDx" :options="['Normale', 'Ridotta']"></b-form-select>
              <strong>Biotes. Primo Dito Piede Sx</strong>
              <br /><b-form-select v-model="jsonData.biotesiometriaPrimoDitoPiedeSx" :options="['Normale', 'Ridotta']"></b-form-select>
            </b-col>
            <b-col xs="0" sm="0" md="2" lg="2" xl="2"></b-col>
            <b-col xs="12" sm="12" md="7" lg="7" xl="7">
              <strong></strong>
              <br />
              <b-form-input v-model="jsonData.biotesiometriaMalleoloDxText"></b-form-input>
              <strong></strong>
              <br />
              <b-form-input v-model="jsonData.biotesiometriaMalleoloSxText"></b-form-input>
              <strong></strong>
              <br />
              <b-form-input v-model="jsonData.biotesiometriaPrimoDitoPiedeDxText"></b-form-input>
              <strong></strong>
              <br />
              <b-form-input v-model="jsonData.biotesiometriaPrimoDitoPiedeSxText"></b-form-input>
            </b-col>
          </b-row>
          <hr />
          <b-row stacked="xl">
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <strong>Elettromiografia</strong>
              <br /><b-form-textarea v-model="jsonData.elettromiografia" rows="8"></b-form-textarea>
            </b-col>
          </b-row>
          <hr />
          <b-row stacked="xl">
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <strong>Visita Cardioangiologica</strong>
              <br /><b-form-textarea v-model="jsonData.visitaCardioangiologica" rows="8"></b-form-textarea>
            </b-col>
          </b-row>
          <hr />
          <b-row stacked="xl">
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <strong>ECG</strong>
              <br /><b-form-textarea v-model="jsonData.ecg" rows="8"></b-form-textarea>
            </b-col>
          </b-row>
          <hr />
          <b-row stacked="xl">
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <strong>Doppler</strong>
              <br /><b-form-textarea v-model="jsonData.doppler" rows="8"></b-form-textarea>
            </b-col>
          </b-row>
          <hr />
          <b-row stacked="xl">
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <strong>Visita Oculistica</strong>
              <br /><b-form-textarea v-model="jsonData.visitaOculistica" rows="8"></b-form-textarea>
            </b-col>
          </b-row>
          <hr />
          <b-row stacked="xl">
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <strong>Fluoroangiografia</strong>
              <br /><b-form-textarea v-model="jsonData.fluoroangiografia" rows="8"></b-form-textarea>
            </b-col>
          </b-row>
          <hr />
        </b-tab>
        <b-tab>
          <template #title>
            <span class="text-primary"><b-icon icon="file-earmark-medical"></b-icon>&nbsp;Allegati</span>
          </template>
          <template class="tab-body"> </template>
          <hr />
          <controlli-specialistici-allegati-edit></controlli-specialistici-allegati-edit>
          <hr />
          <controlli-specialistici-allegati-list></controlli-specialistici-allegati-list>
        </b-tab> </b-tabs
    ></template>
  </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import axios from "axios";
import moment from "moment";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../../utility/UtilityMixin";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import ControlliSpecialisticiAllegatiList from "./ControlliSpecialisticiAllegatiList.vue";
import ControlliSpecialisticiAllegatiEdit from "./ControlliSpecialisticiAllegatiEdit.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { SaPageLayout, PazienteAnagrafica, ControlliSpecialisticiAllegatiList, ControlliSpecialisticiAllegatiEdit },
  data() {
    return {
      linkback: null,
      pathResource: "/diabetologiaanamnesifisiologica",
      pathMisurazioni: "/pazientemisure",
      showModalLoading: false,
      showModalFrequenza: false,
      showmodalSpo2: false,
      showmodalPressione: false,
      currentPage: 1,
      perPage: 10,
      id: "-1",
      file1: null,
      profili: [],
      misurazioneCardiaca: [],
      misurazioniPressione: [],
      misurazioneSpo2: [],
      jsonData: { idPaziente: this.idPaziente },
      jsonDataAllegati: { idPaziente: this.idPaziente },
      provenienzaOption: [{ value: null, text: null }],
      dataInizio: "2021-11-22 00:00:00",
      dataFine: "2021-11-22 23:00:00",
      selected: {},
      opzioniCtrlDietetico: [
        { text: "Buono", value: "Buono" },
        { text: "Mediocre", value: "Mediore" },
        { text: "Cattivo", value: "Cattivo" },
        { text: "Pessimo", value: "Pessimo" },
      ],
      opzioniQuantitaAlcool: [
        { text: "<1/2 Lt", value: "<1/2 Lt" },
        { text: "1/2 Lt", value: "1/2 Lt" },
        { text: "1-2 Lt", value: "1-2 Lt" },
        { text: ">2 Lt", value: ">2 Lt" },
      ],
      opzioniQuantitaFumo: [
        { text: "0 - 10/gg", value: "0 - 10/gg" },
        { text: "11 - 20/gg", value: "11 - 20/gg" },
        { text: "21 - 40/gg", value: "21 - 40/gg" },
        { text: ">40/gg", value: ">40/gg" },
      ],
      opzioniQuantitaCaffe: [
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: ">3", value: ">3" },
      ],
      opzioniAffaticabilita: [
        { text: "SI", value: "SI" },
        { text: "NO", value: "NO" },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    dataEventoPlaceholder() {
      return this.$i18n.t("patient.events.lblEventDate");
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo.menuGruppo) {
          if (this.utente.gruppo.menuGruppo.length > 1) {
            return "view/" + this.idPaziente;
          } else {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "view/" + this.idPaziente;
              } else {
                return "homepaziente";
              }
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    },
  },
  mounted: function() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Diabetologia - Controlli Specialistici";
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/diabetologia/controllispecialistici/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    if (me.id !== "-1") {
      me.linkback = "/paziente/diabetologia/controllispecialistici/view/" + me.id + "?idPaziente=" + me.idPaziente;
      me.loadData();
    } else {
      me.linkback = "/paziente/diabetologia/controllispecialistici?idPaziente=" + me.idPaziente;
    }
  },
  methods: {
    onRowSelected(items) {
      let me = this;
      me.selected = items;
    },
    onCalcoloBmi(altezza, peso) {
      let me = this;
      me.jsonData.bmi = (peso / ((altezza / 100) * (altezza / 100))).toFixed(2);
    },
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
      } else {
        return "---";
      }
    },
    onAggiungiFrequenza() {
      let me = this;
      me.jsonData.frequenzaCardiaca = me.selected[0].value;
      me.showModalFrequenza = false;
    },
    onAggiungiDs() {
      let me = this;
      me.jsonData.pressioneSistolica = me.selected[0].pressioneMax;
      me.jsonData.pressioneDiastolica = me.selected[0].pressioneMin;
      me.showmodalPressione = false;
    },
    onAggiungiSpo() {
      let me = this;
      me.jsonData.spo2 = me.selected[0].value;
      me.showmodalSpo2 = false;
    },
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.id).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
  },
};
</script>
